.room-reservation {
  .simple-select select {
    font-size: 18px;
  }

  &__contract {
    margin-bottom: 20px;
    font-size: 14px;
    color: $color-dark-grey;

    b {
      font-weight: 500;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 747px) {
      display: block !important;

      .datepicker__label {
        font-size: 14px;
        line-height: 20px;
        padding-right: 8px;
      }

      .datepicker__input {
        font-size: 14px;
        line-height: 20px;
        width: auto;
      }
    }
  }

  &__hours {
    margin-bottom: 20px;
    font-size: 18px;
    color: $color-dark-grey;

    @media (max-width: 747px) {
      font-size: 14px;
    }

    i {
      font-style: normal;
      color: $color-red;
    }
  }

  &__main-title {
    @media (max-width: 747px) {
      margin: 0 20px 24px;
    }
  }

  &__info-block {
    display: none;
    @media (max-width: 747px) and (orientation: portrait) {
      padding: 12px 20px;
      background: rgba(115, 201, 196, 0.3);
      border-radius: 3px;
      display: flex;
      align-items: center;
      & > img {
        margin-right: 20px;
      }
      & > p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #555;
        margin: 0;
      }

      margin-bottom: 24px;
    }
  }

  &__location {
    align-items: center;
    display: flex;
    margin-bottom: 25px;
    @media (max-width: 1366px) {
      margin-bottom: 32px;
    }
  }

  &__location-title {
    color: #555;
    font-size: 11px;
    font-weight: $font--light;
    margin: 0;
    width: 155px;
    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__reservation-result {
    color: #555;
    font-size: 18px;
    font-weight: $font--medium;
    line-height: 1.1111111;
    margin-bottom: 10px;
    text-align: center;
    //@media (max-width: 747px) {
    //  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08);
    //  margin: 0 -20px;
    //  padding-top: 16px;
    //  display: flex;
    //  flex-direction: column;
    //  align-items: center;
    //  font-size: 14px;
    //  line-height: 20px;
    //}
    &_empty {
      @media (max-width: 747px) {
        box-shadow: none;
        padding-top: 0;
      }
    }
  }

  &__reservation-information-error {
    @media (max-width: 747px) {
      margin-bottom: 0;
    }
  }

  &__book-link {
    display: inline-flex;
    align-items: center;
    padding: 0;
    font-size: 18px;
    line-height: 1.4;
    text-decoration: none;
    background: none;
    border-width: 0;
    color: $color-red;
    cursor: pointer;

    @media (max-width: 747px) {
      margin-top: 12px;
      font-size: 14px;
    }
  }

  &__btn {
    margin-left: 10px;
    padding-right: 15px !important;
    padding-left: 15px !important;

    span {
      display: inline-flex;
      align-items: center;

      &::after {
        content: '';
        width: 9px;
        height: 15px;
        margin-left: 10px;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%209%2015%22%20width%3D%229px%22%20height%3D%2215px%22%20fill%3D%22%23fff%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M8.735%2C7.390%20C8.735%2C7.675%208.626%2C7.961%208.411%2C8.178%20L2.555%2C14.074%20C2.123%2C14.509%201.423%2C14.509%200.990%2C14.074%20C0.559%2C13.639%200.559%2C12.933%200.990%2C12.498%20L6.063%2C7.390%20L0.990%2C2.282%20C0.559%2C1.846%200.559%2C1.142%200.990%2C0.706%20C1.423%2C0.271%202.123%2C0.271%202.555%2C0.706%20L8.411%2C6.602%20C8.626%2C6.820%208.735%2C7.105%208.735%2C7.390%20Z%22/%3E%3C/svg%3E');
        background-repeat: no-repeat;
      }
    }

    &--with-loader {
      span {
        opacity: 0 !important;
      }

      & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%, -50%);
      }
    }
  }
}
