.page {
  background-color: white;
  color: $color-black;
  display: flex;
  flex-direction: column;
  font-size: $font--page;
  font-weight: $font--light;
  height: 100%;
  line-height: $line-height--base;
  margin: 0;
  min-width: $width--min;
  padding: 0;
  @media (max-width: 1366px) {
    min-width: 100vw;
    max-width: 100vw;
  }

  &.is-modal-window-open {
    height: 100vh;
    min-height: 1000px;
    overflow: hidden;
  }

  &__app-root {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    padding-left: 280px;

    @media (max-width: 1366px) {
      padding-left: 0;
      flex-direction: column;
    }
  }

  &__sidebar {
    @media (min-width: 320px) {
      display: none;
    }
    @media (min-width: 1366px) {
      background-color: #fff;
      color: $workki-dark-main;
      display: block;
      flex: none;
      width: 280px;
      z-index: 11;
      background-attachment: fixed;
      position: fixed;
      left: 0;
      height: 100%;
      top: 0;
    }
  }
  &__footer {
    @media (min-width: 320px) {
      background-color: #fff;
      color: $workki-dark-main;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      z-index: 11;
      background-attachment: fixed;
      position: fixed;
      left: 0;
      height: 56px;
      padding: 6px 7px 8px 7px;
      bottom: 0;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
    }
    @media (min-width: 1366px) {
      display: none;
    }
  }

  &__content {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 0 0;
    position: relative;
    margin: 0 auto;

    .main {
      flex-grow: 1;
    }

    .footer {
      flex-shrink: 0;
    }

    @media (max-width: 1366px) {
      padding: 32px 24px 0;
      width: 100%;
    }
    @media (max-width: 747px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__custom-scrollbar {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__custom-scrollbar-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: auto;
    @media (max-width: 1366px) {
      min-height: calc(100vh - 100px);
    }

    // IE11 hook
    @media all and (-ms-high-contrast: none) {
      height: 100vh;
    }
  }

  /* Кастомизация скроллбара */
  &__custom-scrollbar::-webkit-scrollbar {
    width: 12px;
  }

  &__custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &__custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &__custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &__title,
  &__employees {
    color: $color-brand;
    font-size: 24px;
    font-weight: $font--medium;
    line-height: 1.2;
    margin: 0 0 25px;
    text-transform: uppercase;
    @media (max-width: 747px) {
      margin-bottom: 12px;
      line-height: 32px;
    }

    @media (max-width: 747px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }

    &_payment {
      @media (max-width: 1366px) {
        margin-bottom: 32px;
      }
      @media (max-width: 747px) {
        margin-left: 20px;
        margin-bottom: 24px;
      }
    }
    &_service-group {
      @media (max-width: 1366px) {
        margin-bottom: 32px;
      }
      @media (max-width: 747px) {
        margin-left: 20px;
        margin-bottom: 24px;
      }
    }
  }
  &__employees {
    font-size: 20px;
  }

  &__section {
    background: #fff;
    border-radius: $radius-default;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
    margin-bottom: 40px;
    min-width: 980px;
    padding: 25px 35px 45px;

    &--grow {
      flex-grow: 1;
    }
    @media (max-width: 1366px) {
      min-width: 100%;
      padding: 40px;
    }

    @media (max-width: 747px) {
      padding: 24px 20px 31px 20px;
      border-radius: 0;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 80vh;
    flex-grow: 1;
  }

  &__mobile-header {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      width: 100%;
      z-index: 11;
      height: 100%;
      max-height: 70px;
      background: #323754;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      position: relative;
    }
  }

  &__mobile-menu-list {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 70px;
    display: flex;
    flex-direction: column;
    //width: 100vw;
    width: 100%;
    max-width: 320px;
    height: calc(100vh - 70px);
    background: #323754;
    padding: 20px 0;
    &::after {
      content: '';
      height: calc(100vh - 70px);
      width: calc(100vw - 320px);
      left: calc(-100vw + 320px);
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      //left: 0;
      top: 0;
    }

    transition: visibility 0.3s, opacity 0.3s;
    &_open {
      opacity: 1;
      visibility: visible;
      transition: visibility 0.3s, opacity 0.3s;
    }

    & > div {
      .sidebar__support.btn {
        margin-bottom: 16px;
        margin-top: 0;
      }

      padding: 0 20px;
      border: 0 solid rgba(240, 240, 240, 0.1);
      border-bottom-width: 1px;
    }

    & .main-menu__item {
      margin-bottom: 0;
      padding: 0 20px;
      border: 0 solid rgba(240, 240, 240, 0.1);
      border-bottom-width: 1px;
      & > div {
        border: none;
        padding: 0;
        & > a {
          margin: 16px 0;
          display: block;
        }
        & > span.main-menu__link {
          margin: 16px 0;
          display: block;
        }
        & .accordion__item {
          border: none;
          margin-left: 8px;
        }
      }
    }
  }

  &__icons-container {
    display: flex;
    align-items: center;
  }

  &__menu-btn {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-left: 22px;
    cursor: pointer;
  }

  &__close-btn-menu {
    background-color: transparent;
    border: none;
    display: flex;
    font-size: 0;
    height: 32px;
    position: relative;
    width: 32px;
    margin-left: 22px;
    outline: none;
    padding: 0;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      background-color: #fff;
      content: '';
      height: 2px;
      position: absolute;
      right: 0;
      transition: $transition--default;
      width: 32px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  .mobile-auth-header {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      min-height: 70px;
      background-color: #323754;
      a {
        margin: 0;
        padding: 0;
        height: auto;
        width: auto;
      }
    }
  }
}

main {
  display: block;
}
