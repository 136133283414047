.it-services {
    width: 970px;
    padding: 37px 37px 47px 37px;

    &__form {
        width: 692px;
    }

    &__form-error {
        width: 100%;
        height: 0;
        margin: 0;
        font-size: 14px;
        background-color: rgba(250, 189, 153, 0.3);
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2017%2017%22%20width%3D%2217px%22%20height%3D%2217px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M16.192%2C12.306%20C17.043%2C13.185%2017.043%2C14.562%2016.192%2C15.411%20C15.313%2C16.289%2013.933%2C16.289%2013.083%2C15.411%20L8.800%2C11.164%20L4.547%2C15.411%20C3.695%2C16.289%202.287%2C16.289%201.437%2C15.411%20C0.586%2C14.562%200.586%2C13.185%201.437%2C12.306%20L5.690%2C8.060%20L1.436%2C3.813%20C0.586%2C2.934%200.586%2C1.557%201.436%2C0.708%20C2.287%2C-0.170%203.695%2C-0.170%204.546%2C0.708%20L8.800%2C4.955%20L13.083%2C0.708%20C13.933%2C-0.170%2015.312%2C-0.170%2016.191%2C0.708%20C17.042%2C1.558%2017.042%2C2.934%2016.191%2C3.813%20L11.909%2C8.060%20L16.192%2C12.306%20Z%22/%3E%3C/svg%3E');
        background-position: 20px 50%;
        background-repeat: no-repeat;
        border-radius: 3px;
        color: #555;
        opacity: 0;
        transition: $transition--default;

        .it-services__form.has-error & {
            height: auto;
            padding: 13px 15px 12px 52px;
            margin-top: 30px;
            opacity: 1;
        }
    }

    &__form-textarea {
        margin-bottom: 35px;

        .it-services__form.has-error & {
            border-color: $color-red;
        }
    }

    &__form-submit {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
