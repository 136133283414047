//Brand colours
$workki-dark-main: #24272a !default;
$workki-dark-fill: #383c40 !default;
$workki-dark-secondary: #575b60 !default;
$workki-dark-middle: #8f9295 !default;
$workki-light-line: #c8c9ca !default;
$workki-light-light2: #e9eaea !default;
$workki-light-light1: #f4f4f4 !default;
$workki-light-light05: #fafafa !default;
$workki-light-white: #fff !default;

$workki-red-main: #ba393a !default;
$workki-red-fill: #db4344 !default;
$workki-red-line: #f1b4b4 !default;
$workki-red-light: #fdf6f6 !default;

$workki-green-main: #008d64 !default;
$workki-green-fill: #00a676 !default;
$workki-green-line: #99dbc8 !default;
$workki-green-light: #f2fbf8 !default;

$workki-blue-main: #005f7a !default;
$workki-blue-fill: #00708f !default;
$workki-blue-line: #00708f !default;
$workki-blue-light: #f2f8f9 !default;
