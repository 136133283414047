.dashboard {
  @media (max-width: 747px) {
    margin: 0 20px;
  }

  &__section {
    margin-bottom: 41px;
    @media (max-width: 1366px) {
      margin-bottom: 60px;
    }
    @media (max-width: 747px) {
      margin-bottom: 40px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 1366px) {
      margin-bottom: 32px;
    }
    @media (max-width: 747px) {
      margin-bottom: 24px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    @media (max-width: 1366px) {
      margin-bottom: 0;
    }
  }

  &__events-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__events-item {
    height: 198px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__account-state {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: #73c9c4;
    margin-bottom: 20px;
    @media (max-width: 1366px) {
      margin-bottom: 0;
    }
    @media (max-width: 747px) {
      margin-top: 13px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__account-subhead {
    margin-right: 10px;
  }

  &__account-currency,
  &__account-integer {
    font-size: 20px;
    line-height: 23px;
    @media (max-width: 747px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__account-fraction {
    font-size: 14px;
  }

  &__account-currency {
    margin-left: 5px;
  }

  &__workplaces-list {
    padding: 0;
    margin: 0 0 10px 0;
    list-style: none;
    @media (max-width: 747px) {
      margin: 0 -20px;
    }
  }

  &__workplaces-item {
    padding-bottom: 25px;
    margin-bottom: 10px;
    @media (max-width: 1366px) {
      padding: 40px;
      margin-bottom: 24px;
    }
    @media (max-width: 747px) {
      padding: 24px 20px;
      margin-bottom: 16px;
    }
  }
}
