.community {
  width: 980px;
  @media (max-width: 1366px) {
    width: 100%;
  }

  .profile-social {
    @media (max-width: 1366px) {
      margin-top: 32px;
    }
    @media (max-width: 747px) {
      margin: 24px 20px 0;
    }
  }

  &__navigation-container {
    @media (max-width: 1366px) {
      display: flex;
      flex-direction: column;
      margin-top: 18px;
    }
  }

  &__navigation-btn-open-status {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #f05e4b;
      border: none;
      background-color: transparent;
      outline: none;
      padding: 0;
      cursor: pointer;
      align-items: center;
    }
  }

  &__arrow {
    margin-left: 10px;
    transform: rotate(180deg);
    transition: transform 0.3s;
    &_open {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }

  &__status {
    @media (max-width: 1366px) {
      opacity: 0;
      height: 0;
      visibility: hidden;
    }
    &_open {
      @media (max-width: 1366px) {
        opacity: 1;
        height: auto;
        visibility: visible;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 1366px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 747px) {
      margin: 0 20px;
    }
  }

  &__reverseContainer {
    display: flex;
    flex: 1;
    @media (max-width: 1366px) {
      flex-direction: column-reverse;
      width: 100%;
      margin-top: 32px;
    }
  }

  &__notification {
    margin-top: 30px;
    @media (max-width: 747px) {
      margin: 30px 20px 0;
    }
  }

  &__title {
    margin: 0 25px 0 0;
  }

  &__navigation-list {
    display: inline-block;
    vertical-align: top;
    padding: 0 0 11px 0;
    margin: 0 0 0 16px;
    list-style: none;
    border-bottom: 1px solid $color-red;

    &:first-of-type {
      margin-left: 0;
    }

    @media (max-width: 1366px) {
      margin-left: 0;
      display: block;
      width: fit-content;
      margin-bottom: 4px;
      border-bottom: 0;
      padding-bottom: 8px;
    }
  }

  &__navigation-item {
    display: inline-block;
    vertical-align: top;
    margin-left: 2.5px;
    margin-right: 2.5px;

    &:first-of-type {
      margin-left: 0;
      padding-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }
    @media (max-width: 1366px) {
      margin-left: 0;
      margin-right: 0;
      padding: 16px 11px 0;
      border-bottom: 1px solid $color-red;
    }
  }

  &__navigation-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: $font--medium;
    line-height: 20px;
    color: $color-red;
    text-transform: uppercase;
    overflow: visible;
    @media (max-width: 1366px) {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      position: relative;

      &::before {
        position: absolute;
        z-index: 3;
        content: '';
        bottom: -15px;
        left: 50%;
        width: 7px;
        height: 7px;
        transform: translate(-50%);
        border-radius: 50%;
        background-color: $color-red;
      }

      &::after {
        position: absolute;
        content: '';
        bottom: -18px;
        left: 50%;
        width: 14px;
        height: 14px;
        transform: translate(-50%);
        background-color: $color-bg--page;
      }
    }
  }

  &__list {
    margin-top: 17px;
  }

  &__card {
    display: flex;
    padding: 39px 42px;
    margin-bottom: 20px;
    background-color: $color-white;
    border-radius: $radius-default;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);

    &--single {
      border: 1px solid rgba(85, 85, 85, 0.2);
      margin-bottom: 0;
    }

    @media (max-width: 1366px) {
      padding: 40px;
    }

    @media (max-width: 747px) {
      border-radius: 0;
      padding: 20px 24px;
    }
  }

  &__card-avatar {
    align-self: flex-start;
    flex: none;
    margin-right: 21px;
    @media (max-width: 747px) {
      display: none;
    }
  }

  &__content-column-type {
    @media (max-width: 747px) {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
      }
    }
  }

  &__navigation-btn-container {
    display: flex;
    align-items: center;
  }

  &__navigation-reset-btn {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: $color-new-light-gray;
    padding: 0;
    border: 0 dotted $color-new-light-gray;
    outline: none;
    background: transparent;
    border-bottom-width: 1px;
    cursor: pointer;
    margin: 0 0 0 30px;
  }

  &__card-information {
    padding-top: 20px;
    .profile-avatar {
      display: none;
    }
    @media (max-width: 747px) {
      padding-top: 0;
      display: flex;
      .profile-avatar {
        display: block;
        margin-bottom: 0;
        max-width: 48px;
        max-height: 48px;
        margin-right: 12px;
      }
    }
  }

  &__card-title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: $font--medium;
    line-height: 20px;
    color: $color-dark-grey;
    @media (max-width: 1366px) {
      line-height: 28px;
    }
    @media (max-width: 747px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }

  &__card-description {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.66;
    font-weight: 300;

    p {
      font-size: 16px;
      line-height: 1.66;
      font-weight: 300;
      overflow-wrap: anywhere;
    }

    p + p {
      margin-top: 10px;
    }
    @media (max-width: 747px) {
      margin-top: 16px;
      p {
        margin: 0;
      }
    }
  }

  &__card-link {
    overflow-wrap: anywhere;
  }

  &__loader {
    height: 80vh;
  }
}
