.event {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &__content {
        position: relative;
        height: 100%;
        padding: 24px 33px 20px 31px;
        color: $color-white;

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            will-change: opacity;
            transition-property: opacity;
            transition-duration: 250ms;
            transition-timing-function: ease-out;
        }
    }

    &__header {
        position: relative;
    }

    &__date {
        font-size: 24px;
        font-weight: $font--medium;
        line-height: 1.1;
        color: $color-red;
    }

    &__checkbox {
        position: absolute;
        top: 20px;
        right: 33px;
    }

    &__footer {
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: calc(100% - 63px);
    }

    &__title {
        margin: 0 0 2px 0;
        font-size: 24px;
        font-weight: $font--medium;
        line-height: 1.1;
    }

    &__description {
        width: 700px;
        font-size: 16px;
        font-weight: $font--light;
        line-height: 26px;
    }

    &__cost {
        font-size: 16px;
        font-weight: $font--medium;
        line-height: 1.7;
        color: $color-light-turquoise;
    }
}
