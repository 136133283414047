.range {
  width: 200px;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  //webkit
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: $workki-light-light2;
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    position: relative;
    width: 15px;
    height: 15px;
    margin-top: -6px;
    border-radius: 50%;
    background: $color-white;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: $workki-light-light2;
  }

  //mozilla
  &::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: $color-white;
    border-radius: 25px;
  }

  &::-moz-range-thumb {
    position: relative;
    width: 15px;
    height: 15px;
    margin-top: -6px;
    border-radius: 50%;
    border-width: 0;
    background: $workki-light-light2;
    cursor: pointer;
    -webkit-appearance: none;
  }

  //IE
  &::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 7px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $workki-light-light2;
    border-width: 0;
    border-radius: 5px;
  }

  &::-ms-fill-upper {
    background: $workki-light-light2;
    border-width: 0;
    border-radius: 5px;
  }

  &::-ms-thumb {
    width: 15px;
    height: 15px;
    margin-top: 0;
    border-radius: 7px;
    border-width: 0;
    background: $color-white;
    cursor: pointer;
  }

  &:focus::-ms-fill-lower,
  &:focus::-ms-fill-upper {
    background: $workki-light-light2;
  }

  &::-ms-tooltip {
    display: none;
  }
}
