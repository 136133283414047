.input {
  background-color: #fff;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 3px;
  color: #555;
  // display: inline-block;
  font-family: $font-stack-base;
  font-size: 18px;
  font-weight: $font--medium;
  height: 38px;
  line-height: 1;
  // padding: 17px 20px 15px;
  padding: 2px 20px 0;
  text-align: left;
  transition: $transition--default;
  vertical-align: middle;
  width: 100%;
  display: inline-flex;
  align-items: center;

  &:focus {
    border-color: rgba(85, 85, 85, 1);
    outline: 0;
  }

  @include placeholder() {
    font-weight: $font--light;
  }

  &.has-error,
  .has-error &:invalid {
    border-color: $color-red;
  }

  &--disabled {
    pointer-events: none;
  }

  input:-webkit-autofill {
    background-color: #fff !important;
  }
}
