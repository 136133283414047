.dashboard-meeting-room-card {
  color: $color-new-gray;
  display: flex;

  @media (max-width: 747px) {
    flex-direction: column;
  }

  &__left-part {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__right-part {
    display: flex;
    flex-direction: column;
  }

  &__links {
    display: flex;

    & > * {
      margin-right: 20px;
    }

    & > *:last-child {
      margin-right: 0;
    }
    &__caption {
      display: flex;
      margin-top: 20px;
      & > :first-child {
        margin-right: 20px;
      }
    }
    & > a {
      text-decoration: none;
    }
  }

  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;

    @media (max-width: 747px) {
      flex-direction: column;
      margin-bottom: 8px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-brand;
    margin: 0 8px 0 0;

    @media (max-width: 747px) {
      flex-direction: column;
      margin: 0 0 8px;
    }
  }

  &__price {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $color-brand;
  }

  &__place-wrapper {
    display: flex;
    align-items: baseline;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    margin-bottom: 10px;
    justify-content: flex-end;

    @media (max-width: 747px) {
      justify-content: flex-start;
    }
  }

  &__place-subtitle {
    margin-right: 8px;
  }

  &__place-number {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #555;
  }

  &__cancel-button {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: $color-new-light-gray;
    background: transparent;
    padding: 3px 9px;
    gap: 10px;
    border: 1px solid $color-new-light-gray;
    border-radius: 4px;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
  }
}
