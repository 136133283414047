.footer {
  color: $color-dark-grey;
  font-size: 12px;
  margin-top: auto;
  text-align: right;
  padding-bottom: 35px;

  &__developer-info {
    display: block;
    line-height: 24px;
  }

  &__developer-info-link {
    color: $color-red;
    font-weight: 200;
    &:hover {
      color: #e64236;
    }
  }

  &__version {
    display: block;
  }

  @media (max-width: 747px) {
    display: none;
  }
}
