.scrollable-table {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  &__title {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.1111111;
    margin: 0 0 20px;
    padding-left: 35px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    flex-grow: 1;
    position: relative;
  }

  &__table-head {
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__scrollbar {
    flex-grow: 1;
    @media (max-width: 1366px) {
      &::after,
      &::before {
        content: '';
        height: 100%;
        width: 60px;
        position: absolute;
        top: 0;
        z-index: 1;
        background: linear-gradient(90deg, #fff 0%, rgba(0, 0, 0, 0) 50%);
      }
      &::before {
        right: 0;
        //left: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #fff 50%);
      }
    }
  }

  &__table-body {
    width: 100%;
    overflow: visible;
  }

  &__table-head-row {
    display: flex;
    align-items: center;
    color: #818181;
    font-weight: 400;
    font-size: 13px;
    height: 40px;
    text-transform: uppercase;
  }

  &__row {
    display: flex;
    align-items: center;
    height: 30px;
    overflow: visible;
    background-color: #f6f6f6;

    &:nth-of-type(2n) {
      background-color: #fff;
    }

    @media (max-width: 1366px) {
      &:nth-of-type(2n) {
        background-color: #fff;
      }
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    padding-left: 30px;

    &:last-of-type {
      padding-right: 10px;
      flex-shrink: 0;
    }

    &--limited {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: inherit;
    }
  }

  &__link-payment {
    color: $color-red;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #ff6054;
    }
  }
  &__loading-status {
    cursor: pointer;
    font-size: 12px;
  }

  &__control {
    color: $color-red;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
      color: #ff6054;
    }
  }

  &--transactions {
    @media (min-width: 1366px) {
      margin-top: 24px;
      min-width: 1250px;
      & .scrollable-table__table {
        min-width: 1200px;
      }

      & .scrollable-table__row {
        height: 48px;
      }

      & .scrollable-table__title {
        padding-left: 40px;
      }
    }
    @media (max-width: 1366px) {
      margin-top: 24px;
      & .scrollable-table__table {
        min-width: 805px;
      }

      & .scrollable-table__row {
        height: 48px;
      }

      & .scrollable-table__title {
        padding-left: 40px;
      }
    }

    @media (max-width: 747px) {
      margin-top: 16px;
      margin-bottom: 0;
      flex: 3;
      & .scrollable-table__row {
        height: 40px;
      }

      & .scrollable-table__title {
        padding-left: 20px;
      }
    }

    .scrollable-table__cell {
      &:first-of-type {
        z-index: 5;
        @media (max-width: 1366px) {
          height: 100%;
          padding-left: 40px;
        }

        @media (max-width: 747px) {
          padding-left: 20px;
        }

        width: 15%;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 15%;
        margin-left: 20px;
        justify-content: flex-start;
      }

      &:nth-of-type(4) {
        justify-content: flex-start;
        width: 55%;
        @media (min-width: 320px) {
          font-size: 12px;
        }
        @media (min-width: 1024px) {
          font-size: 14px;
        }

        line-height: 1;
        white-space: pre-wrap;
        @media (max-width: 1366px) {
          height: 100%;
        }
      }

      &:nth-of-type(5) {
        margin-left: 10px;
        z-index: 5;
        width: 40%;
        height: 100%;
      }
      &:nth-of-type(6) {
        margin-left: 50px;
        z-index: 5;
        width: 20%;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        :first-child {
          padding: 0;
        }
      }
      &:nth-of-type(7) {
        z-index: 5;
        width: 20%;
        height: 100%;
        justify-content: flex-start;
      }

      &--amount {
        font-weight: $font--normal;
        color: #73c9c4;
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  &--tickets {
    @media (max-width: 1366px) {
      margin-top: 24px;
      & .scrollable-table__table {
        min-width: 970px;
      }

      & .scrollable-table__row {
        height: 48px;
      }

      & .scrollable-table__title {
        padding-left: 40px;
      }
    }
    @media (max-width: 747px) {
      margin-top: 16px;
      margin-bottom: 0;
      flex: 3;
      & .scrollable-table__row {
        height: 40px;
      }

      & .scrollable-table__title {
        padding-left: 20px;
      }
    }
    .scrollable-table__cell {
      padding-left: 15px;

      &:first-of-type {
        z-index: 5;
        width: 10%;
        @media (max-width: 1366px) {
          height: 100%;
          padding-left: 40px;
        }

        @media (max-width: 747px) {
          padding-left: 20px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        width: 25%;
      }

      &:nth-of-type(5) {
        width: 15%;
        @media (max-width: 1366px) {
          height: 100%;
        }

        z-index: 5;
      }
    }
  }

  &--payment {
    @media (max-width: 1366px) {
      margin-top: 24px;
      & .scrollable-table__table {
        min-width: 970px;
      }

      & .scrollable-table__row {
        height: 48px;
      }

      & .scrollable-table__title {
        padding-left: 40px;
      }
    }

    @media (max-width: 747px) {
      margin-top: 16px;
      margin-bottom: 0;
      flex: 3;
      & .scrollable-table__row {
        height: 40px;
      }

      & .scrollable-table__title {
        padding-left: 20px;
      }
    }

    .scrollable-table__cell {
      &:first-of-type {
        z-index: 5;
        width: 12%;

        @media (max-width: 1366px) {
          height: 100%;
          padding-left: 40px;
        }

        @media (max-width: 747px) {
          padding-left: 20px;
        }
      }

      &:nth-of-type(2) {
        width: 15%;
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
        width: 13%;
      }

      &:nth-of-type(4) {
        width: 19%;
      }

      &:nth-of-type(5) {
        width: 14%;
      }

      &:nth-of-type(6) {
        width: 6%;
      }

      &:nth-of-type(7) {
        width: 12%;

        & > p {
          margin: 0;
        }
      }

      &:nth-of-type(8) {
        @media (max-width: 1366px) {
          height: 100%;
        }

        z-index: 5;
        width: 10%;
      }

      &--paid {
        color: #73c9c4;
        font-weight: $font--normal;
      }

      &--not-paid {
        color: $color-light-green;
        font-weight: $font--normal;
      }

      &--payment,
      &--amount-payment {
        font-weight: $font--normal;
      }
    }
  }

  &__header-filter {
    margin-left: -8px;
    width: 100%;
  }

  .scrollable-table__cell {
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    min-width: 100px;
    box-sizing: border-box;
    &__upd {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      & > span {
        padding: 0;
      }
    }
  }
}
