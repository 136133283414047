// Font families
// @type List
$font-stack-base: Comissioner, sans-serif !default;

// Font-sizes
// @type Length
$font--page: 14px !default;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 300 !default; // or thin
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or semi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Colors
// @type Color
$color-brand: #323754 !default;
$color-primary: #000 !default;
$color-secondary: #000 !default;
$color-black: #222 !default;
$color-red: #f15a4f;
$color-white: #fff;
$color-dark-grey: #555;
$color-grayscale-black: #282f36;
$color-grayscale-pure-white: #fff;
$color-light-grey: #b6b6b6;
$color-light-green: #c3e500;
$color-light-turquoise: #73c9c4;
$color-base: #f2f2f2;

// New colors
$color-new-gray: #6a6d7e;
$color-new-light-gray: #939393;
// text
$color-text: $color-black !default;

// background
$color-bg--page: #f2f2f2 !default;

// Default line-height
// @type Length
$line-height--base: 1.42857143; // 20/14

// Project specific font sizes
$font--main-heading: 24px;

// Base grid width
// @type Length
$width--min: 1340px !default;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;

// border-radius
$radius-default: 8px;

//workplace-color
$wp-red-main: #f36b74;
$wp-red-plan: #e13e49;
$wp-green-bg: #eafaf2;
$wp-grayscale-main: #cfd1d2;
$wp-grayscale-secondary: #9fa4aa;
$wp-grayscale-middle: #707479;
$wp-grayscale-dark: #40464d;
