.user-info {
  position: relative;
  width: 230px;
  text-decoration: none;
  display: flex;

  @media (max-width: 1366px) {
    & > img:not(&__mobileProfile) {
      display: none;
    }
  }

  &__mobileProfile {
    width: 28px;
    height: 28px;
    max-width: 28px;
    max-height: 28px;
    display: none;
    @media (max-width: 1366px) {
      display: block;
    }
  }

  &--forEdge {
    &::after {
      content: ' ';
      position: relative;
      // display: block;
      // width: 10px;
      // height: 20px;
      top: 0;
      right: 40px;
      border-right: 10px solid rgba(23, 25, 38, 0.85);
      border-left: 10px solid rgba(23, 25, 38, 0.5);
    }

    .user-info__description p {
      overflow: hidden;
      height: 20px;
      width: 165px;
      line-height: 20px;
      // box-shadow: inset -40px 0px 10px 5px rgb(23, 25, 38);
    }
  }

  &__description {
    display: inline-block;
    margin-right: 20px;
    margin-left: 11px;
    position: relative;
    vertical-align: middle;

    &::after {
      border: 1px solid;
      border-color: $color-red $color-red transparent transparent;
      content: '';
      height: 8px;
      position: absolute;
      right: -12px;
      top: 6px;
      transform: rotate(45deg);
      width: 8px;
    }

    & p {
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__avatarLibraryType {
    @media (max-width: 1366px) {
      display: none !important;
    }
  }

  &__avatar {
    position: relative;
    flex-shrink: 0;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: inline-block;
    transition: $transition--default;
    background-color: transparent;
    vertical-align: middle;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      width: 90%;
      height: 90%;
      border-radius: 50%;
      // background-color: $color-light-grey;
      background-color: rgba(255, 255, 255, 0.8);
      top: 5%;
      left: 5%;
      z-index: -1;
    }
    & img {
      display: inline-block;
      height: auto;
      vertical-align: baseline;
      width: 100%;
    }

    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__mail {
    font-size: 16px;
    color: $workki-dark-main;
    font-weight: $font--medium;
    // line-height: 1.25;
    line-height: 1;
    transition: $transition--default;
  }

  &:hover {
    // .user-info__mail {
    //     color: rgba(255, 255, 255, 0.8);
    // }

    .user-info__avatar {
      border-color: rgba(255, 255, 255, 1);
    }
  }

  &__workplace {
    font-size: 12px;
    font-weight: $font--light;
    // line-height: 1.6666667;
    line-height: 1;
  }

  &__notifications {
    position: absolute;
    top: -50%;
    width: 100%;
    height: auto;
    background-color: $color-white;
    border-radius: $radius-default;
    transform: translate(0, -100%);

    &::after {
      position: absolute;
      content: '';
      left: 10%;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: $color-white;
      transform: rotate(-45deg);
    }
  }

  &__notification-btn {
    position: absolute;
    top: 0;
    left: 21px;
    padding: 0;
    width: 13px;
    height: 13px;
    border: 5px solid $color-red;
    background-color: white;
    border-radius: 50%;

    &--empty {
      border-width: 0;
      background-color: $color-red;
    }
  }

  &__notifications-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__notifications-item {
    padding: 16px 18px 10px 19px;
    border-bottom: 1px solid #171926;

    &:last-of-type {
      border-bottom-width: 0;
    }
  }

  &__notifications-description {
    display: block;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: $font--medium;
    line-height: 1.2;
    color: $color-dark-grey;
  }

  &__notifications-date {
    margin-right: 3px;
    font-size: 10px;
    font-weight: $font--light;
    line-height: 18px;
    color: $color-light-grey;
  }

  &__notifications-time {
    font-size: 10px;
    font-weight: $font--medium;
    line-height: 18px;
    color: $color-dark-grey;
  }

  &__notifications-close {
    position: absolute;
    top: 0;
    right: -30px;
    width: 16px;
    height: 16px;
    overflow: visible;

    &::before,
    &::after {
      background-color: #fff;
      content: '';
      height: 2px;
      position: absolute;
      top: 5px;
      right: 2px;
      transition: $transition--default;
      width: 16px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__notifications-show-all {
    display: block;
    width: 152px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 2px auto 16px auto;
    font-size: 13px;
    font-weight: $font--medium;
    line-height: 1.6;
  }

  @media (max-width: 1366px) {
    width: auto;
    height: min-content;
  }
}
