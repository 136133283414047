.notifications {
    width: 970px;
    min-height: 520px;

    &__table-wrap {
        margin-left: -35px;
        margin-right: -45px;
    }

    &__table {
        width: 100%;
        margin-bottom: 25px;
        border-spacing: 0;
        table-layout: fixed;
    }

    &__table-row {
        color: #888888;

        &--head {
            height: 36px;
            color: $color-dark-grey;
        }

        .notifications__table-body & {
            &:nth-child(even) {
                background-color: #f6f6f6;
            }
        }

        &--unread {
            font-weight: $font--medium;
            color: $color-dark-grey;
        }
    }

    &__table-cell {
        font-size: 14px;
        line-height: 1.95;
        padding: 2px 0;

        &:first-of-type {
            font-size: 12px;
            width: 145px;
            padding-left: 35px;
        }

        &:nth-of-type(2) {
            padding-left: 30px;
            padding-right: 45px;
        }

        &--head {
            font-size: 13px;
            font-weight: $font--light;
            line-height: 1.8;
            text-align: left;
            text-transform: uppercase;
        }
    }

    &__table-cell-description {
        position: relative;
        display: block;
        width: 100%;

        .notifications__table-row--unread & {
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: -14px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                transform: translateY(-50%);
                background-color: $color-dark-grey;
            }
        }
    }

    &__show-more {
        display: block;
        padding: 3px 20px;
        margin-right: auto;
        margin-left: auto;
        font-size: 13px;
        line-height: 1.6;
        border-radius: 4px;
    }
}
