.tooltip {
    border: 1px solid rgba(85, 85, 85, 0.1);
    color: $color-dark-grey !important;
    background-color: $color-white !important;

    &::after {
        width: 13px;
        height: 13px;
        border-width: 1px;
        background-color: $color-white !important;
        box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
    }

    &.place-top {
        &:after {
            transform: rotate(45deg);
            border-top-color: transparent !important;
        }
    }

    &.place-left {
        &:after {
            top: 47%;
            transform: rotate(-45deg);
            border-left-color: transparent !important;
        }
    }
}
