.profile {
  width: 970px;

  &__location-picker {
    margin-bottom: 0;
  }

  &__info-pannel {
    margin-bottom: 10px;

    &__personal {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    }
  }

  .desktop-phone-number,
  .desktop-identifier {
    @media (max-width: 1366px) {
      display: none;
    }
  }
  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 747px) {
    //flex: 1;
    margin-bottom: 0;
    height: 100%;
  }
}
