.prefill-profile {
  padding: 50px 50px 25px 65px;
  width: 970px;
  @media (max-width: 1366px) {
    width: 100%;
    padding: 40px;
  }
  @media (max-width: 747px) {
    padding: 32px 20px;
    margin-bottom: 0;
  }

  &__warning {
    background-color: rgba(250, 189, 153, 0.3);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%209%2021%22%20width%3D%229px%22%20height%3D%2221px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M7.364%2C4.539%20C7.854%2C4.080%208.099%2C3.523%208.099%2C2.869%20C8.099%2C2.217%207.854%2C1.660%207.364%2C1.196%20C6.875%2C0.733%206.286%2C0.500%205.596%2C0.500%20C4.910%2C0.500%204.315%2C0.733%203.823%2C1.196%20C3.333%2C1.660%203.085%2C2.217%203.085%2C2.869%20C3.085%2C3.523%203.333%2C4.080%203.823%2C4.539%20C4.315%2C4.998%204.910%2C5.229%205.596%2C5.229%20C6.286%2C5.229%206.875%2C4.998%207.364%2C4.539%20ZM6.236%2C19.887%20C7.119%2C19.471%207.587%2C18.688%207.127%2C18.330%20C6.864%2C18.124%206.517%2C18.465%206.263%2C18.465%20C5.720%2C18.465%205.337%2C18.375%205.118%2C18.195%20C4.895%2C18.013%204.788%2C17.676%204.788%2C17.178%20C4.788%2C16.980%204.820%2C16.688%204.887%2C16.303%20C4.954%2C15.917%205.033%2C15.571%205.118%2C15.270%20L6.168%2C11.523%20C6.266%2C11.179%206.339%2C10.801%206.379%2C10.390%20C6.415%2C9.975%206.435%2C9.688%206.435%2C9.526%20C6.435%2C8.737%206.160%2C8.095%205.611%2C7.600%20C5.064%2C7.107%204.282%2C6.858%203.268%2C6.858%20C2.705%2C6.858%202.105%2C6.942%201.478%2C7.161%20C0.246%2C7.596%200.520%2C8.676%200.793%2C8.676%20C1.348%2C8.676%201.720%2C8.771%201.916%2C8.960%20C2.113%2C9.148%202.214%2C9.482%202.214%2C9.964%20C2.214%2C10.228%202.179%2C10.526%202.116%2C10.847%20C2.052%2C11.168%201.976%2C11.510%201.881%2C11.870%20L0.825%2C15.631%20C0.735%2C16.026%200.667%2C16.380%200.622%2C16.694%20C0.579%2C17.008%200.560%2C17.315%200.560%2C17.616%20C0.560%2C18.388%200.842%2C19.025%201.407%2C19.528%20C1.973%2C20.032%202.842%2C20.500%203.862%2C20.500%20C4.524%2C20.500%205.470%2C20.252%206.236%2C19.887%20Z%22/%3E%3C/svg%3E');
    background-position: 25px 8px;
    background-repeat: no-repeat;
    border-radius: 3px;
    color: #555;
    display: inline-block;
    margin-bottom: 32px;
    padding: 11px 35px 11px 50px;

    & p {
      font-weight: $font--light;
      margin: 0 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    & strong {
      font-weight: $font--medium;
    }

    @media (max-width: 747px) {
      padding-right: 12px;
    }
  }

  &__list-container {
    @media (max-width: 747px) {
      width: 100%;
      background: #ffffff;
      border: 1px solid rgba(85, 85, 85, 0.2);
      border-radius: 3px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      padding-right: 24px;
      margin-bottom: 40px;

      &:after {
        content: '\A';
        border-style: solid;
        border-width: 7px 10px 7px 0;
        border-color: transparent #f05e4b transparent transparent;
        position: absolute;
        right: 16px;
        transform: rotate(270deg);
        transition: transform 0.3s;
      }
    }
  }
  &__section-list {
    list-style: none;
    margin: 0 0 34px;
    padding: 0;
    @media (max-width: 747px) {
      display: none;
    }
  }

  &__section-item {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 12px;
      @media (max-width: 747px) {
        margin-left: 0;
      }
    }

    @media (max-width: 747px) {
      width: 100%;
    }
  }

  &__section-btn {
    font-size: 16px;
    line-height: 1.2;
    padding: 11px 27px 10px;
    text-transform: uppercase;
    @media (max-width: 747px) {
      width: 100%;
      padding: 6px;
      background-color: transparent;
      border-radius: 0;
      border: none;
      color: #323754;
      font-size: 14px;
      line-height: 20px;
      &:hover:not(:disabled) {
        background-color: #e0ebfd;
        color: #323754;
      }
    }
  }

  &__list-label-style {
    display: none;
    @media (max-width: 747px) {
      display: initial;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #f15a4f;
    }
  }
  .active-item {
    @media (max-width: 747px) {
      background: #73c9c4;
      color: #ffffff;
    }
  }

  .visible-list {
    @media (max-width: 747px) {
      position: absolute;
      width: 100%;
      left: 0;
      top: 44px;
      z-index: 10;
      background-color: #ffffff;
      margin: 0;
      border: 1px solid rgba(85, 85, 85, 0.2);
      box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .rotate-icon {
    &:after {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
}
