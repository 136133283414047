html {
  background-color: $color-bg--page;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $color-bg--page;
  color: $color-black;
  font-family: $font-stack-base;
  font-size: $font--page;
  font-weight: $font--normal;
  line-height: $line-height--base;
  height: 100%;
  margin: 0;
  min-width: $width--min;
  padding: 0;
  -webkit-overflow-scrolling: touch;

  & *:focus {
    outline: none;
  }

  &.user-is-tabbing *:focus {
    outline: 2px dashed $color-brand;
  }

  &.ov-hidden {
    overflow: hidden;
  }
  @media (max-width: 1366px) {
    min-width: 100vw;
    max-width: 100vw;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $line-height--base;
}

h1 {
  font-size: $font--main-heading;
  line-height: 1.2;
}

b,
strong {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $line-height--base;
}

i,
em {
  font-family: $font-stack-base;
  font-style: italic;
  font-weight: $font--normal;
  line-height: $line-height--base;
}

p {
  font-family: $font-stack-base;
  font-weight: $font--normal;
  line-height: $line-height--base;
}

a,
img {
  border: none;
  outline: none;
}

a,
button {
  transition: $transition--default;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.input {
  background-color: #fff;
  display: inline-block;
  font-family: $font-stack-base;
  font-size: 18px;
  padding: 0 15px;
  position: relative;
  vertical-align: middle;
  text-align: left;

  &[disabled] {
    background-color: #f5f6f7;
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.main {
  width: 100%;
  font-family: Commissioner, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  align-items: center;

  &::after {
    content: '';
    height: 60px;
  }

  @media (min-width: 1366px) {
    &::after {
      height: 0;
    }
  }
}

.main-auth {
  font-family: Commissioner, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 1366px) {
    justify-content: center;
  }
  @media (min-width: 1920px) {
    justify-content: flex-start;
  }

  background-color: white;
  align-items: center;
}
