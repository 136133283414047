.not-found-error {
  &__code {
    color: $color-red;
    text-align: center;
    font-weight: $font--medium;
    font-size: 220px;
    @media (max-width: 1366px) {
      margin-top: 10px;
    }
    @media (max-width: 747px) {
      font-size: 110px;
      margin-top: 50px;
    }
  }

  &__description {
    text-align: center;
    font-size: 18px;
    font-weight: $font--light;
    color: $color-brand;
    @media (max-width: 1366px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 747px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
