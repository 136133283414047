.table-select {
  position: relative;
  width: 100%;
  height: 25px;
  color: $color-black;
  font-weight: $font--light;
  text-transform: none;

  &__control {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    border: 1px solid $color-light-grey;
    background-color: $color-white;
    border-radius: 4px;
    cursor: pointer;
    outline: 0;
    transition: $transition--default;

    &--is-disabled,
    &--is-disabled:hover {
      cursor: default;
      background-color: $color-base;
      border-color: $color-light-grey;
    }
  }

  &__value-container {
    position: relative;
    display: flex;
    flex: 1 1 0;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px 0 2px 7px;
    font-size: 12px;
    line-height: 1.1;
    overflow: hidden;
    color: inherit;
    font-weight: inherit;
    text-transform: inherit;
  }

  &__single-value {
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    max-width: calc(100% - 3px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__indicators {
    padding: 2px;
  }

  &__dropdown-indicator {
    position: relative;
    width: 10px;
    height: 5px;
    background-color: transparent;
    transition: $transition--default;
    color: $color-dark-grey;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: 4px solid currentColor;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;

      .table-select__control--is-disabled & {
        color: $color-dark-grey;
      }
    }

    & > svg {
      display: none;
    }

    .table-select__control--menu-is-open & {
      transform: rotate(-180deg);
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    letter-spacing: 0.02em;
    color: inherit;
    transform: translateY(-50%);
    white-space: nowrap;
    font-weight: $font--light;
    font-size: 12px;
    line-height: 1.1;
  }

  &__menu {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    margin: 5px 0;
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }

  &__menu-list {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__option,
  &__menu-notice {
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 5px 4px 7px;
    border-bottom: 1px solid $color-light-grey;
    cursor: default;
    user-select: none;
    color: $color-dark-grey;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 12px;
    line-height: 1.1;

    &:last-child {
      border-bottom: none;
    }

    &--is-focused {
      background-color: $color-base;
      color: $color-black;
      outline: none;
    }

    &--is-selected {
      background-color: $color-base;
      color: $color-black;
    }
  }
}

.table-select-wrap {
  width: 100%;
}
