.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $color-red !important;
}

.workplace-booking {
  &__interactive-section {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
    width: 565px;
    @media (max-width: 1366px) {
      width: 100%;
    }
    &_mobile {
      display: none;
      @media (max-width: 1366px) {
        display: block;
        max-width: 380px;
        margin-right: 0;
        width: 100%;
        margin-top: 16px;
      }
      @media (max-width: 747px) {
        margin-top: 0;
      }
    }
    &_desktop {
      @media (max-width: 1366px) {
        display: none;
      }
    }
  }

  &__conditions {
    @media (max-width: 1366px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__picture {
    display: inline-block;
    vertical-align: top;
    min-height: 180px;
    min-width: 288px;
    @media (max-width: 747px) {
      display: none;
    }
    &--with-preloader {
      height: 180px;
      width: 288px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > div {
        height: auto;
        margin-bottom: -7px;
      }

      & > div > svg {
        fill: $color-red;
      }
      &__solid {
        border-radius: 3px;
        color: $color-white;
        fill: $color-white;
        padding: 7px 27px 6px;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 1366px) {
      margin-bottom: 8px;
      flex-wrap: wrap;
    }
    &__unfixed-select {
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }

  &__interactive-field {
    display: inline-block;

    &:nth-child(even) {
      text-align: right;
      @media (max-width: 1366px) {
        text-align: left;
      }
    }

    &:nth-child(odd) {
      text-align: left;
    }
    @media (max-width: 1366px) {
      //width: 50%;
      margin-bottom: 16px;
      text-align: left;
    }
    &_flex {
      display: flex;
      width: 100%;
      align-items: center;
      .datepicker__input {
        font-size: 14px;
        line-height: 20px;
        width: auto;
      }
      .workplace-booking__label {
        margin-bottom: 0;
        margin-right: 8px;
        display: flex;
        align-items: center;
        white-space: pre;
      }
      .number-spinner__input {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &_contract {
      flex-grow: 1;
      margin-right: 20px;
      max-width: 300px;
    }
  }

  &__contract-select {
    flex-grow: 1;
  }
  &__dropdown-btn {
    background-color: transparent;
    border: none;
    color: $color-dark-grey;
    font-size: 18px;
    font-weight: $font--medium;
    height: 20px;
    padding: 0 22px 0 4px;
    position: relative;

    &::after {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2011%207%22%20width%3D%2211px%22%20height%3D%227px%22%20fill%3D%22%23555555%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M5.276%2C6.599%20C5.064%2C6.599%204.852%2C6.519%204.691%2C6.358%20L0.308%2C2.005%20C-0.017%2C1.684%20-0.017%2C1.163%200.308%2C0.842%20C0.630%2C0.521%201.155%2C0.521%201.479%2C0.842%20L5.276%2C4.613%20L9.074%2C0.842%20C9.398%2C0.521%209.921%2C0.521%2010.245%2C0.842%20C10.568%2C1.163%2010.568%2C1.684%2010.245%2C2.005%20L5.862%2C6.358%20C5.700%2C6.519%205.489%2C6.599%205.276%2C6.599%20Z%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      content: '';
      height: 7px;
      opacity: 0.8;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      transition: $transition--default;
      width: 11px;
    }

    .modal-window__interactive-field.disabled & {
      line-height: 1.2;
      opacity: 0.8;
      pointer-events: none;
    }

    @media (max-width: 1366px) {
      white-space: nowrap;
      padding: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      &::after {
        content: none;
      }
    }
  }

  &__checkbox {
    align-self: center;
    @media (max-width: 1366px) {
      margin-bottom: 28px;
    }
  }

  &__space-between-container {
    @media (max-width: 1366px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  /* stylelint-disable no-descending-specificity */
  &__label {
    color: $color-dark-grey;
    display: block;
    font-size: 18px;
    font-weight: $font--medium;
    padding-right: 5px;

    .modal-window__interactive-field.disabled & {
      color: rgba(85, 85, 85, 0.6);
    }
    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      padding: 0;
      text-align: left;
      margin-bottom: 4px;
    }
  }

  &__dropdown-list {
    display: none;
  }

  &__current-option {
    color: $color-dark-grey;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 20px;
    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 1366px) {
      margin-bottom: 16px;
    }
  }

  &__info-text {
    @media (max-width: 1366px) {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: #555;
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .text-light-weight {
    @media (max-width: 1366px) {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: #555;
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  &__comment {
    margin-top: 20px;
    color: #555;
    font-family: Rubik, Arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5384615;
  }

  &__book-submit {
    background-color: transparent;
    border: none;
    color: $color-red;
    font-size: 18px;
    font-weight: $font--normal;
    text-decoration: none;
    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .btn--solid {
    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__book-submit-text {
    color: $color-dark-grey;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  &__book-submit-text_lack {
    color: $color-red;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  &__book-submit-text_amount {
    line-height: 24px;
    font-size: 22px;
    margin-top: 4px;
  }
}
.overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 12;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guest-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 320px) {
    min-width: 300px;
    max-width: 300px;
    padding: 20px 20px 0 20px;
  }
  @media (min-width: 768px) {
    min-width: 450px;
    max-width: 450px;
    padding: 20px 40px 0 40px;
  }
  //width: fit-content;
  background: $color-grayscale-pure-white;
  color: $color-grayscale-black;
  opacity: 1;
  font-size: 14px;
  & > a {
    font-size: 14px;
    padding: 10px;
  }

  visibility: visible;
  font-family: Commissioner, sans-serif;
  transition: opacity 0.1s;
}

.guest-modal-wrapper {
  display: flex;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: 320px) {
    min-width: 300px;
    max-width: 300px;
  }
  @media (min-width: 768px) {
    min-width: 450px;
    max-width: 450px;
    padding: 10px 20px 20px 20px;
  }

  pointer-events: visible;
}
.guest-title {
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  //font-size: 16px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  & > label {
    font-size: 18px;
    //font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
  }
}
.guest-modal-cross {
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  align-content: center;
  & > svg {
    height: 18px;
    width: 18px;
  }
  &:hover,
  :active {
    cursor: pointer;
    & > svg {
      & path {
        fill: #282f36;
      }
    }
  }
}
.guest-button {
  margin-bottom: 16px;
}
