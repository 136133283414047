.shooting {
    display: flex;
    align-items: flex-start;
    padding: 37px 31px 47px 39px;

    &__form-location-picker {
        margin-bottom: 35.5px;
    }

    &__form-row {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
    }

    &__form-label {
        margin-right: 16px;
        font-size: 18px;
        line-height: 1.1;
        font-weight: $font--medium;
        color: $color-dark-grey;
    }

    &__form-datepicker-input.input {
        width: auto;
        height: 18px;
        padding: 0;
        border: none;
        color: #f15a4f;
        text-align: left;
        text-transform: lowercase;
    }

    &__form-toggle-calendar.btn {
        position: relative;
        width: 20px;
        height: 18px;
        background: transparent;
        font-size: 0;

        &::after {
            content: "";
            position: absolute;
            right: 50%;
            top: 50%;
            width: 11px;
            height: 7px;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2011%207%22%20width%3D%2211px%22%20height%3D%227px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M5.276%2C6.599%20C5.064%2C6.599%204.852%2C6.519%204.691%2C6.358%20L0.308%2C2.005%20C-0.017%2C1.684%20-0.017%2C1.163%200.308%2C0.842%20C0.630%2C0.521%201.155%2C0.521%201.479%2C0.842%20L5.276%2C4.613%20L9.074%2C0.842%20C9.398%2C0.521%209.921%2C0.521%2010.245%2C0.842%20C10.568%2C1.163%2010.568%2C1.684%2010.245%2C2.005%20L5.862%2C6.358%20C5.700%2C6.519%205.489%2C6.599%205.276%2C6.599%20Z%22/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            transform: translateX(50%) translateY(-50%);
            transition: $transition--default;

            .flatpickr-input.active + & {
                transform: rotate(-180deg) translateX(-50%) translateY(50%);
            }
        }
    }

    &__form-textarea {
        margin-bottom: 37px;
        font-size: 16px;
        line-height: 1.44;
        max-width: 696px;
        min-width: 696px;
    }

    &__form-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__form-pseudo-btn {
        padding: 7px 38px 6px 17px;
        margin-left: 12px;
    }

    &__form-action-text {
        font-size: 18px;
        font-weight: $font--medium;
        line-height: 1;
        color: $color-red;
    }

    &__form-footer {
        font-size: 16px;
        font-weight: $font--medium;
        line-height: 1.2;
        color: $color-dark-grey;
    }

    &__form-action {
        margin-left: 20px;
        margin-right: 20px;
        color: $color-red;
    }

    &__aside {
        width: 184px;
        margin-left: 28px;
    }

    &__aside-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__aside-item {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #fef2eb;
        border-radius: 8px;
    }

    &__aside-title {
        padding-left: 20px;
        margin-bottom: 23px;
        font-size: 16px;
        line-height: 1.2;
    }

    &__aside-parameter {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 3px;
        display: block;
        font-size: 16px;
        font-weight: $font--medium;
        line-height: 22px;
        color: $color-red;

        &:last-child {
            margin-top: 7px;
            margin-bottom: 0;
        }
    }

    &__aside-card-btn {
        margin-top: 8px;
        margin-left: 20px;
        padding: 3px 17px;
        font-size: 13px;
        line-height: 1.6;
        border-radius: 8px;
    }

    &__aside-item {
        position: relative;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__aside-close-item-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
        font-size: 0;
        height: 13px;
        width: 13px;

        &::before,
        &::after {
            background-color: $color-red;
            content: "";
            height: 2px;
            position: absolute;
            right: 2px;
            transition: $transition--default;
            width: 13px;
            opacity: 0.5;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover {
            cursor: pointer;

            &::before {
                transform: rotate(-45deg);
                opacity: 1;
            }

            &::after {
                transform: rotate(45deg);
                opacity: 1;
            }
        }
    }

    &__aside-card-notification {
        display: block;
        width: 100%;
        padding: 4px 20px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: $font--medium;
        line-height: 22px;
        color: $color-white;
        background-color: $color-red;
        text-transform: uppercase;
    }

    &__form-choose-time {
        display: flex;
        align-items: center;
    }

    &__form-choose-time-input {
        display: inline-block;
        width: auto;
        height: 18px;
        padding: 0;
        font-family: $font-stack-base;
        font-size: 18px;
        font-weight: $font--medium;
        line-height: 1;
        border: none;
        color: $color-red;
        text-transform: lowercase;
        vertical-align: middle;

        .shooting__form.has-error & {
            border: 1px solid $color-red;
        }

        //in IE we remove the cross from input
        &::-ms-clear {
            display: none;
        }

        @include placeholder {
            color: $color-red;
        }
    }

    &__form-error {
        width: 100%;
        height: 0;
        margin: 0;
        font-size: 14px;
        background-color: rgba(250, 189, 153, 0.3);
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2017%2017%22%20width%3D%2217px%22%20height%3D%2217px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M16.192%2C12.306%20C17.043%2C13.185%2017.043%2C14.562%2016.192%2C15.411%20C15.313%2C16.289%2013.933%2C16.289%2013.083%2C15.411%20L8.800%2C11.164%20L4.547%2C15.411%20C3.695%2C16.289%202.287%2C16.289%201.437%2C15.411%20C0.586%2C14.562%200.586%2C13.185%201.437%2C12.306%20L5.690%2C8.060%20L1.436%2C3.813%20C0.586%2C2.934%200.586%2C1.557%201.436%2C0.708%20C2.287%2C-0.170%203.695%2C-0.170%204.546%2C0.708%20L8.800%2C4.955%20L13.083%2C0.708%20C13.933%2C-0.170%2015.312%2C-0.170%2016.191%2C0.708%20C17.042%2C1.558%2017.042%2C2.934%2016.191%2C3.813%20L11.909%2C8.060%20L16.192%2C12.306%20Z%22/%3E%3C/svg%3E');
        background-position: 20px 50%;
        background-repeat: no-repeat;
        border-radius: 3px;
        color: #555;
        opacity: 0;
        transition: $transition--default;

        .shooting__form.has-error & {
            height: auto;
            padding: 13px 15px 12px 52px;
            margin-top: 30px;
            opacity: 1;
        }
    }
}
