.modal-window {
  background: #fff;
  border-radius: 5px;
  margin: 0 auto 35px;
  position: absolute;
  text-align: center;
  top: 90px;
  width: 970px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 1366px) {
    transform: none;
    width: 100%;
    left: auto;
    margin: 0;
    top: 45px;
  }
  .input__placeholder::placeholder {
    color: #555;
    font-weight: 500;
  }

  @media (max-width: 747px) {
    top: 65px;
    border-radius: 0;
    height: calc(100vh - 81px);
    //padding-bottom: 20px;
  }

  &--short {
    width: 450px;
    @media (max-width: 1366px) {
      width: 60%;
      left: 50%;
      transform: translate(-50%);
    }
    @media (max-width: 747px) {
      width: 100%;
      left: 0;
      transform: none;
    }
  }

  &--fill-payments {
    width: 550px;
    @media (max-width: 1366px) {
      left: 50%;
      transform: translate(-50%);
    }
    @media (max-width: 747px) {
      width: 100%;
      left: 0;
      transform: none;
      .modal-window__title {
        font-size: 18px;
        padding-right: 16px;
      }
    }

    &_guest {
      @media (max-width: 1366px) {
        margin-bottom: 20px;
        .fill-payments-modal__body {
          max-width: 280px;
          width: 100%;
          margin: 0 auto;
        }
        .big-select {
          max-height: 64px;
          .react-select__control {
            height: 64px;
          }
        }
        .fill-payments-modal__file-input-button {
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .react-select__placeholder {
          font-size: 16px;
          line-height: 20px;
          text-align: left;
        }
        .input {
          font-size: 16px;
          line-height: 20px;
        }
        .datepicker__wrapper {
          display: block;
          .datepicker__label {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
          }
          .datepicker__input {
            font-size: 14px;
            line-height: 20px;
            width: auto;
          }
        }
        .entrance-form__information-msg {
          margin-top: 0;
          margin-bottom: 24px;
        }
        .fill-payments-modal.has-error .fill-payments-modal__notification {
          display: flex;
          justify-content: center;
          align-items: center;
          .notification {
            max-width: 280px;
            background-size: 6% 17px;
          }
        }
        .notification--fill-payment-modal {
          max-width: 280px;
          background-size: 6% 17px;
          margin: 0 auto;
        }
        .fill-payments-modal__refill-btn {
          margin-top: 0;
        }
      }
      @media (max-width: 747px) {
        margin-bottom: 0;
      }
    }

    &_parking {
      @media (max-width: 1366px) {
        margin: 0 auto;
        .datepicker {
          flex: 1;
        }
        .datepicker__wrapper {
          display: flex;
          .datepicker__label {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
          }
          .datepicker__input {
            font-size: 14px;
            line-height: 20px;
            width: auto;
            flex: 1;
            letter-spacing: 0.5px;
            //min-width: 126px;
          }
        }
        .textarea {
          width: 100%;
          min-width: auto;
          font-size: 16px;
        }
      }
    }

    &_tmc {
      @media (max-width: 1366px) {
        margin-bottom: 20px;
        .datepicker {
          flex: 1;
        }
        .datepicker__label {
          font-size: 14px;
          line-height: 20px;
        }
        .datepicker__input {
          font-size: 14px;
          line-height: 20px;
          width: auto;
          flex: 1;
          letter-spacing: 0.5px;
          max-width: 126px;
        }
        .tmc-modal-container {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .tmc-modal-container-left {
            min-width: 280px;
            width: 40%;
          }
          .tmc-modal-container-right {
            min-width: 280px;
            width: 40%;
          }
          .tmc-modal-neogeo__select {
            width: 100%;
            margin: 0 0 24px 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .react-select__single-value {
            font-size: 16px;
            line-height: 24px;
          }
          .inp-container {
            width: 100%;
          }
          .input {
            width: 100%;
            margin: 0;
            height: 40px;
          }
          .textarea {
            width: 100%;
            min-width: auto;
            font-size: 16px;
          }
          .prefill-form__row {
            margin-bottom: 24px;
            p {
              margin: 0;
              text-align: left;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #555;
            }
          }
        }
        .prefill-form__footer {
          padding-top: 32px;
        }

        .notification--fill-payment-modal {
          max-width: 400px;
          width: 100%;
          margin: 0 auto;
          background-size: 6% 17px;
        }
      }
      @media (max-width: 747px) {
        margin-bottom: 0;
        .tmc-modal-container {
          flex-direction: column;
          justify-content: flex-start;
          .tmc-modal-container-left {
            width: 100%;
          }
          .tmc-modal-container-right {
            width: 100%;
          }
          .tmc-modal-neogeo__select {
            margin: 0 0 16px 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .prefill-form__row {
            margin-bottom: 16px;
            p {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &__header {
    color: #fff;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -50px;
    width: 100%;
    @media (max-width: 747px) {
      top: -65px;
      padding-left: 20px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: $font--medium;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    @media (max-width: 747px) {
      text-align: left;
    }
  }

  &__body {
    padding: 42px 40px 40px;
    @media (max-width: 1366px) {
      padding: 30px;
    }
    @media (max-width: 747px) {
      overflow: auto;
      height: 100%;
      padding: 32px 20px;
    }
  }

  &__close-btn {
    background-color: transparent;
    border-color: transparent;
    display: block;
    font-size: 0;
    height: 25px;
    position: relative;
    width: 25px;

    &::before,
    &::after {
      background-color: #fff;
      content: '';
      height: 2px;
      position: absolute;
      right: 2px;
      transition: $transition--default;
      width: 21px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }

    @media (max-width: 747px) {
      margin-right: 20px;
    }
  }

  &-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: calc(100% - 310px);
    margin-left: 310px;
    z-index: 100; // This must be at a higher index to the rest of your page content
    transform: translateZ(0);

    & > div > div {
      @media (max-width: 747px) {
        overflow: hidden !important;
      }
    }

    @media (max-width: 1366px) {
      width: 100%;
      margin-left: 0;
      padding: 102px 24px 0;
    }

    @media (max-width: 747px) {
      padding: 16px 0 0;
    }

    &::before {
      border-left: 9999px solid rgba(0, 0, 0, 0.8);
      box-shadow: 9999px 0 0 rgba(0, 0, 0, 0.8);
      bottom: 0;
      content: ' ';
      left: -9999px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      display: block;
    }
  }
}
