.dashboard-workplaces-card {
  color: $color-new-gray;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1366px) {
      align-items: flex-start;
    }
    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__information {
    display: flex;
    align-items: center;
    @media (max-width: 747px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__title {
    margin-right: 5px;
    color: #323754;
    @media (max-width: 1366px) {
      font-size: 16px;
      line-height: 24px;
      margin-right: 8px;
    }
    @media (max-width: 747px) {
      margin-bottom: 4px;
    }
  }

  &__description {
    margin-right: 19px;
    font-size: 13px;
    line-height: 24px;
  }

  &__action {
    display: flex;
    align-items: center;
    @media (max-width: 1366px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    @media (max-width: 747px) {
      align-items: flex-start;
    }
  }

  &__notification {
    position: relative;
    padding-left: 10px;
    font-size: 11px;
    font-weight: $font--medium;
    line-height: 20px;

    &--active {
      color: $color-light-green;

      &::before {
        background-color: $color-light-green;
      }
    }

    &--inactive {
      color: $color-red;

      &::before {
        background-color: $color-red;
      }
    }

    &::before {
      position: absolute;
      content: '';
      top: 7px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }

  &__notification-period {
    margin-left: 2px;
  }

  &__place {
    color: #787878;
  }

  &__btn {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    font-weight: $font--medium;
    border-radius: 8px;
    @media (max-width: 1366px) {
      margin-top: 6px;
    }
  }

  &__place-number {
    margin-left: 5px;
    margin-right: 27px;
    font-size: 18px;
    font-weight: $font--medium;
    line-height: 24px;
    color: #6c6c6c;
    @media (max-width: 1366px) {
      margin-right: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__checkbox {
    margin-left: 29px;
    @media (max-width: 1366px) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__footer {
    display: flex;
    align-items: baseline;
    @media (max-width: 1366px) {
      margin-top: -20px;
    }
    @media (max-width: 747px) {
      margin-top: 0;
    }
  }

  &__date {
    display: flex;
    margin-left: 15px;
    font-size: 13px;
    line-height: 24px;
    &_mobile {
      display: none;
      @media (max-width: 747px) {
        display: flex;
        margin: 0 0 4px 0;
      }
    }
    &_desktop {
      @media (max-width: 747px) {
        display: none;
      }
    }
  }

  &__rest {
    color: $color-red;
  }

  &__rest-day {
    margin-left: 4px;
    font-weight: $font--medium;
  }

  &__period {
    margin-left: 7px;
  }

  &__status-container_mobile {
    display: none;
    @media (max-width: 747px) {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
  &__status-container_desktop {
    display: flex;
    align-items: center;
    @media (max-width: 747px) {
      display: none;
    }
  }
  &__price-container {
    display: flex;
    align-items: center;
  }
}
