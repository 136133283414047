.number-spinner {
    &__input {
        pointer-events: none;
        border: none;
        background-color: transparent;
        color: $color-red;
        font-size: 18px;
        font-weight: $font--medium;
        width: 25px;
        text-align: center;
    }

    &__btn {
        border: none;
        background-color: transparent;
        color: $color-red;
        font-size: 18px;
        font-weight: $font--medium;
        height: 25px;
        width: 25px;

        &:hover {
            cursor: pointer;
        }
    }
}
