.fill-payments-modal {
  &__row {
    margin-bottom: 20px;
    @media (max-width: 1366px) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__errorRow {
    color: $color-red;
    font-size: 16px;
    font-weight: 300;
  }

  &__guestRow {
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  &__guestTitleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 30px;

    &:first-of-type {
      margin-top: 40px;
    }
  }

  &__guestRemoveButton {
    border: none;
    background: transparent;
    font-size: x-large;
    height: fit-content;
  }

  &__addGuestButtonWrapper {
    justify-content: start;
    display: flex;
    margin-top: 40px;
  }

  &__addGuestButton {
    width: 100%;
    font-family: Rubik, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding: 7px 27px 6px;
    border: none;
  }

  &__checkbox {
    text-align: left;
    display: flex;
    flex-direction: column;
    @media (min-width: 320px) {
      label {
        padding-left: 30px !important;
      }
      & label::before {
        width: 18px !important;
        height: 18px !important;
      }

      & label::after {
        width: 18px !important;
        height: 10px !important;
        top: 1px !important;
        left: 3px !important;
      }
    }
  }

  &__notification {
    display: none;
    margin-top: 20px;

    .fill-payments-modal.has-error & {
      display: block;
    }
  }

  &__refill-btn {
    @media (max-width: 1366px) {
      margin-top: 10px;
    }
    &--with-preloader {
      width: 217px;

      & > div {
        margin-bottom: -7px;
      }
    }
    @media (max-width: 747px) {
      width: 100%;
    }
  }

  &__file-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 5px;
      cursor: pointer;

      &--empty {
        font-weight: $font--light;
        cursor: default;
      }
    }

    &-button {
      line-height: 1;
      border-radius: 6px;
      text-transform: lowercase;
      height: 26px;
      padding: 3px 15px;
    }

    input {
      display: none;
    }
  }
}
