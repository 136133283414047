.print-product-card {
  &__title {
    margin-bottom: 10px;
    color: $color-red;
    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  &__contract {
    margin-bottom: 25px;
    color: $color-red;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: $font--medium;
    line-height: 23px;
    color: $color-dark-grey;
  }

  &__action {
    display: flex;
    align-items: center;
    @media (max-width: 1366px) {
      justify-content: space-between;
    }
  }

  &__price {
    margin-right: 30px;
    font-size: 20px;
    font-weight: $font--medium;
    line-height: 23px;
    color: $color-red;
    @media (max-width: 1366px) {
      font-size: 16px;
      line-height: 24px;
      margin-right: 0;
    }
  }

  &__wrap-counter {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: $font--light;
    line-height: 1;
    @media (max-width: 1366px) {
      margin-bottom: 24px;
    }
  }

  &__submit {
    padding-left: 24px;
    padding-right: 22px;
    @media (max-width: 747px) {
      height: 40px;
      width: 180px;
    }
  }
}
