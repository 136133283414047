.profile-social {
  padding-top: 20px;
  padding-bottom: 56px;
  margin-left: -280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 11;
  @media (max-width: 1366px) {
    margin-left: 0;
  }
  &--with-warning {
    padding-top: 5px;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 600px;
    @media (max-width: 1366px) {
      width: 416px;
    }
    @media (max-width: 768px) {
      width: 340px;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__warning {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-repeat: no-repeat;
    color: $workki-blue-main;
    display: flex;
    gap: 4px;
    width: 100%;
    max-width: 600px;
    &::before {
      margin-top: 1px;
      content: url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2213%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%205.5C6.27614%205.5%206.5%205.72386%206.5%206V9C6.5%209.27614%206.27614%209.5%206%209.5C5.72386%209.5%205.5%209.27614%205.5%209V6C5.5%205.72386%205.72386%205.5%206%205.5Z%22%20fill%3D%22%23005F7A%22%2F%3E%3Cpath%20d%3D%22M6%204.5C6.27614%204.5%206.5%204.27614%206.5%204C6.5%203.72386%206.27614%203.5%206%203.5C5.72386%203.5%205.5%203.72386%205.5%204C5.5%204.27614%205.72386%204.5%206%204.5Z%22%20fill%3D%22%23005F7A%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%206.5C0%203.18629%202.68629%200.5%206%200.5C9.31371%200.5%2012%203.18629%2012%206.5C12%209.81371%209.31371%2012.5%206%2012.5C2.68629%2012.5%200%209.81371%200%206.5ZM6%201.5C3.23858%201.5%201%203.73858%201%206.5C1%209.26142%203.23858%2011.5%206%2011.5C8.76142%2011.5%2011%209.26142%2011%206.5C11%203.73858%208.76142%201.5%206%201.5Z%22%20fill%3D%22%23005F7A%22%2F%3E%3C%2Fsvg%3E');
    }
    &--danger {
      background-position: 20px 50%;
      font-weight: $font--medium;
    }
  }

  &__visibility {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    margin-bottom: 25px;
    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__visibility-label {
    padding-right: 10px;

    & a {
      color: $color-dark-grey;
      font-weight: $font--medium;
      text-decoration: none;

      &:hover {
        opacity: 0.9;
      }
    }
    @media (max-width: 747px) {
      & span {
        display: block;
        margin-bottom: 16px;
      }
    }
  }

  &__visibility-toggle {
    display: inline-block;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 12px 0 20px;

    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }
  }

  &__sub-title {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: $font--light;
    text-align: center;
    color: $color-dark-grey;
    display: inline-block;
  }

  &__status {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    margin-left: auto;
  }

  &__status-label {
    padding-right: 10px;
  }

  &__status-value {
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: $font--medium;
    text-transform: uppercase;
    line-height: 1.4285714;

    &--danger {
      color: $color-red;
    }

    &--quiet {
      opacity: 0.5;
    }
  }
}

.profile-social-form {
  &__textarea-container {
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 15px;
      @media (max-width: 1366px) {
        margin-bottom: 24px;
      }
    }
    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__input {
    &--name {
      width: 400px;
      margin-right: 25px;
      flex-grow: 0;
      @media (max-width: 1366px) {
        width: 100%;
        flex-grow: 1;
      }
      @media (max-width: 747px) {
        max-width: 100%;
        margin-bottom: 24px;
      }
    }

    &--site {
      width: 320px;
      flex-grow: 1;
      @media (max-width: 1366px) {
        width: 100%;
      }
      @media (max-width: 747px) {
        max-width: 100%;
      }
    }
  }

  &__textarea {
    min-width: 764px;
    max-width: 764px;
    padding-bottom: 5px;
    @media (max-width: 1366px) {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  &__submit {
    min-width: 150px;
    @media (max-width: 747px) {
      width: 100%;
    }
  }

  &__footer {
    padding-top: 30px;
    text-align: center;
    overflow: hidden;

    .has-error & {
      padding-bottom: 20px;
    }

    &--invisible {
      padding-top: 0;
      transform: all 0.2s;
      & > .profile-social-form__submit {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        transform: all 0.2s;
      }
    }
  }

  &__field-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
    gap: 4px;
    @media (max-width: 1366px) {
      width: 100%;
    }
  }

  .setting-margin-input {
    margin-bottom: 0;
  }

  &__dark-back {
    background: black;
  }
}
