.profile-payment-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  font-size: 0;
  //@media (max-width: 747px) {
  //  padding-top: 0;
  //}

  &__row {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 15px;
      @media (max-width: 747px) {
        margin-bottom: 24px;
      }
    }
    &--forFiles {
      justify-content: flex-start;
      @media (max-width: 747px) {
        flex-direction: row !important;
      }
    }

    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__input {
    width: 48%;
    &:not(:last-child) {
      margin-right: 30px;
    }
    @media (max-width: 747px) {
      width: 100%;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__textarea {
    min-width: 764px;
    max-width: 764px;
    padding-bottom: 5px;
  }

  label {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: $font--light;
    text-align: center;
    color: $color-dark-grey;
    display: inline-block;
    @media (max-width: 747px) {
      margin-bottom: 4px;
    }
  }

  &__submit {
    min-width: 150px;
  }

  &__file {
    &:not(:last-child) {
      margin-right: 15px;
    }
    @media (max-width: 747px) {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  &__footer {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    overflow: hidden;

    &--invisible {
      padding-top: 0;
      transform: all 0.2s;
      & > .profile-social-form__submit {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        transform: all 0.2s;
      }
    }
  }
}

.payment-form-names-row {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;

  @media (min-width: 1366px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }
}
