.hours-picker {
  &__table {
    border-collapse: collapse;
    margin-bottom: 20px;
    @media (max-width: 1366px) {
      margin-bottom: 0;
    }
  }

  &__main-table-container {
    @media (max-width: 1366px) {
      display: flex;
      margin-bottom: 40px;
    }
  }
  &__mobile-fixed-titles {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: max-content;
      margin-right: 12px;
    }
  }

  &__mobile-title-container {
    @media (max-width: 1366px) {
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: space-between;
      &_first {
        height: 25px;
        align-items: flex-start;
        justify-content: flex-end;
        button {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-bottom: 25px;
      }
    }
  }

  &__mobile-title {
    @media (max-width: 1366px) {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #555;
      margin: 0 6px 0 0;
    }
  }

  &__mobile-icon {
    @media (max-width: 1366px) {
      margin: 0;
      color: #f05e4b;
      font-size: 16px;
    }
  }

  &__mobile-btn {
    @media (max-width: 1366px) {
      width: 16px;
      height: 16px;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 17px;
      img {
        object-fit: contain;
      }
    }
  }

  &__blur-container {
    @media (max-width: 1366px) {
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        width: 40px;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 50%);
        top: 0;
        right: -1px;
        z-index: 1;
      }
    }
  }

  &__table-overflow {
    @media (max-width: 1366px) {
      overflow-x: auto;
      padding-bottom: 25px;
      padding-left: 10px;
      position: relative;
    }
    &_off {
      @media (max-width: 1366px) {
        overflow-x: hidden;
      }
    }
  }
  &__room_desktop,
  &__table-title_desktop {
    @media (max-width: 1366px) {
      display: none;
    }
  }
  &__table-title_mobile {
    display: none;
    @media (max-width: 1366px) {
      display: block;
      margin-bottom: 16px;
      margin-top: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $workki-dark-main;
    }
  }

  &__title-content {
    @media (max-width: 1366px) {
      display: flex;
      align-items: center;
    }
  }
  &__mobile-cell {
    border: 1px solid $color-light-grey;
    height: 32px;
    min-width: 56px;
    &:hover {
      cursor: pointer;
    }

    &.is-book,
    &.is-busy {
      background: repeating-linear-gradient(-60deg, #cfd1d2 0, #cfd1d2 1px, transparent 1px, transparent 5px);
    }
    &.is-paid {
      background: repeating-linear-gradient(-60deg, #f1b4b4 0, #f1b4b4 1px, transparent 1px, transparent 5px);
    }
    &.is-current {
      background-color: $workki-dark-main;
    }
    @media (min-width: 320px) {
      height: 32px;
      min-width: 44px;
      &:last-child {
        z-index: 2;
        &::before {
          content: '';
          height: 0;
          min-width: 0;
          position: absolute;
          border: none;
          top: 0;
          left: -1px;
        }
      }
    }

    @media (min-width: 1366px) {
      min-width: 56px;
      max-width: 70px;
    }
  }

  &__cell {
    border: 1px solid $color-light-grey;
    height: 32px;
    min-width: 56px;
    &:hover {
      cursor: pointer;
    }

    &.is-book,
    &.is-busy {
      background: repeating-linear-gradient(-60deg, #cfd1d2 0, #cfd1d2 1px, transparent 1px, transparent 5px);
    }
    &.is-paid {
      background: repeating-linear-gradient(-60deg, #f1b4b4 0, #f1b4b4 1px, transparent 1px, transparent 5px);
    }
    &.is-current {
      background-color: $workki-dark-main;
    }

    @media (max-width: 1366px) {
      height: 32px;
      //width: 56px;
      min-width: 56px;
      &:last-child {
        display: none;
        position: relative;
        z-index: 2;
        border: none;
        &::before {
          content: '';
          height: 32px;
          min-width: 56px;
          position: absolute;
          border: 1px solid $color-light-grey;
          top: 0;
          left: -1px;
        }
      }
    }
  }

  &__row-title {
    border: 1px solid transparent;
    color: #555;
    font-size: 12px;
    font-weight: $font--light;
    position: relative;

    span {
      display: inline-block;
      min-width: 30px;
      position: relative;

      &::after {
        color: $workki-dark-main;
        content: '›';
        position: absolute;
        right: -8px;
        top: 0;
      }
    }
  }

  //&__cell:nth-child(2) {
  //  background: transparent;
  //  border-color: transparent #d9d9d9 transparent transparent;
  //  pointer-events: none;
  //}
  &__head-cell-mobile {
    font-size: 0;
    height: 20px;
    padding: 0 12px;
    position: relative;
    z-index: 2;

    span {
      position: absolute;
      color: #555;
      font-size: 12px;
      font-weight: $font--light;
      top: 0;
      right: -12px;
    }

    &--current {
      span {
        color: $workki-dark-main;
      }
    }

    &--current-extreme {
      span {
        color: $workki-dark-main;
        font-weight: $font--medium;
      }
    }
  }

  &__head-cell {
    font-size: 0;
    height: 25px;
    padding: 2px 0;
    position: relative;
    z-index: 2;

    span {
      position: absolute;
      color: #555;
      font-size: 12px;
      font-weight: $font--light;
      top: 0;
      right: -2px;
    }

    &--current {
      span {
        color: $workki-dark-main;
      }
    }

    &--current-extreme {
      span {
        color: $workki-dark-main;
        font-weight: $font--medium;
      }
    }
  }

  &__table-title {
    color: $color-red;
    font-size: 12px;
    font-weight: $font--medium;
    min-width: 125px;
    text-align: left;

    &::after {
      content: '';
      height: 10px;
      position: absolute;
      right: -15px;
      top: 5px;
      width: 11px;
    }
  }

  &__status-description-list {
    margin-bottom: 50px;
    margin-left: 155px;
    @media (max-width: 1366px) {
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }
    &_desktop {
      @media (max-width: 747px) {
        display: none;
      }
    }
    &_mobile {
      display: none;
      @media (max-width: 747px) {
        display: flex;
      }
    }
  }

  &__status-description-item {
    display: inline-block;
    font-size: 12px;
    font-weight: $font--medium;
    line-height: 1.2;
    margin: 0;
    max-width: 190px;
    padding: 0 15px;
    position: relative;
    vertical-align: middle;

    &::after {
      background-color: currentColor;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
    }

    &--busy {
      color: #d9d9d9;
    }

    &--booked {
      color: #c3e500;
    }

    &--paid {
      color: #323754;
    }

    @media (max-width: 1366px) {
      max-width: none;
    }
  }
}
