.manager {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__img-wrap {
        width: 46px;
        height: 46px;
        margin-right: 13px;
        overflow: hidden;
        border-radius: 50%;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__name {
        color: $color-dark-grey;
        font-size: 16px;
        font-weight: $font--medium;
        line-height: 1.3;
    }

    &__email {
        display: block;
        font-size: 13px;
        font-weight: $font--medium;
        line-height: 1.6;
        color: $color-red;
        text-decoration: none;
    }
}
