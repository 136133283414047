.balance-panel {
  padding: 20px 25px 20px 35px;
  margin-bottom: 10px;

  @media (min-width: 1230px) {
    width: 970px;
  }
  @media (min-width: 1366px) {
    width: 1250px;
  }

  @media (max-width: 1366px) {
    padding: 40px;
  }

  @media (max-width: 747px) {
    flex: 1;
    padding: 24px 20px;
    margin-bottom: 0;
    min-height: 310px;
  }

  &_sm {
    @media (max-width: 747px) {
      min-height: 112px;
      max-height: 112px;
    }
  }

  &_guest {
    .ticket__main-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 747px) {
        flex-direction: column-reverse;
        height: 100%;
      }
    }
    @media (max-width: 1366px) {
      margin-bottom: 24px;
      .balance-panel__inner {
        align-items: center;
        & > .balance-panel__state {
          margin-bottom: 0;
        }
      }
    }
    @media (max-width: 747px) {
      height: min-content;
      min-height: auto;
      max-height: 88px;
      margin-bottom: 16px;
      .balance-panel__action {
        align-items: center;
      }
    }
  }

  &_ticket {
    @media (max-width: 747px) {
      max-height: 133px;
      .balance-panel__inner {
        height: 100%;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1366px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      & > div:first-child {
        margin-bottom: 32px;
      }
      & > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__state {
    color: #73c9c4;
  }

  &__subhead {
    color: #73c9c4;
    font-weight: $font--normal;
    padding-right: 20px;
    &--security {
      font-weight: $font--normal;
      color: #f15a4f;
    }
    @media (max-width: 747px) {
      margin-bottom: 16px;
    }
  }

  &__integer {
    font-size: 20px;
    padding-left: 10px;
    &--security {
      color: #f15a4f;
      font-size: 20px;
      padding-left: 10px;
    }
  }

  &__loader {
    display: inline-block;
    padding-left: 10px;
  }

  &__fraction {
    font-weight: $font--medium;
    font-size: 16px;
  }

  &__currency {
    font-size: 25px;
    padding-left: 10px;
    &--security {
      color: #f15a4f;
      font-size: 25px;
      padding-left: 10px;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    @media (max-width: 747px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__link {
    padding: 7px 18px;
    line-height: 0;
    @media (max-width: 1366px) {
      height: 40px;
    }
    @media (max-width: 747px) {
      width: 100%;
      &[name='visa'] {
        width: 50%;
        align-self: center;
      }
    }

    &_add-guest {
      @media (max-width: 1366px) {
        width: 237px;
      }
      @media (max-width: 747px) {
        max-width: 280px;
        width: 100%;
      }
    }
  }

  &__link-text {
    font-size: 13px;
    line-height: 20px;
  }

  &__title {
    font-weight: $font--medium;
    color: #555;
    font-size: 18px;
  }

  &__time {
    font-weight: $font--light;
    color: #555;
    font-size: 18px;
    padding-left: 15px;
  }

  &__btn-img {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
