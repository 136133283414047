.appeals-history-box {
  margin: 16px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 450px;
  box-sizing: border-box;

  @media screen and (min-width: 1920px) {
    margin-top: 48px;
    overflow: visible;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }

  @media screen and (min-width: 320px) {
    overflow-y: auto;
  }
}

.ticket-history-section {
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-inline: 30px;
  }
}

.contacts-and-managers-title-wrapper {
  display: flex;
  align-items: center;
}

.contacts-and-managers-title {
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #24272a;
}

.appeals-history-list {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
}

.appeals-history-item {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  gap: 12px;
  margin: 0;
  cursor: pointer;
}

.appeals-history-item-description {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & > h4 {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (min-width: 1920px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (min-width: 1920px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.appeals-history-item-info {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;

  & > h4 {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;

    @media screen and (min-width: 1920px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;
    text-align: end;

    @media screen and (min-width: 1920px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.detail-ticket {
  padding-top: 20px;
  padding-bottom: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 13;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
}

.detail-ticket__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 600px;

  @media (max-width: 1366px) {
    width: 416px;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #f4f5f6;
  }
}

.ticket-detail-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.detail-top-btn-wrapper {
  display: flex;
  font-weight: 500;
}

.ticket-detail-accordion-title {
  color: #575b60;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  width: fit-content;
}

.ticket-detail-accordion {
  svg {
    transition: transform 0.25s ease;
  }
}

.ticket-detail-accordion-content {
  margin-top: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ticket-detail-accordion-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  &.is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.detail-ticket-top-block {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-weight: 500;
  padding: 14px 0;
  width: 100%;
  @media (min-width: 320px) {
    font-size: 22px;
    margin: 0;
    h1 {
      margin: 0 !important;
      font-weight: 500;
    }
  }
  @media (min-width: 360px) {
    font-size: 22px;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    padding-inline: 16px;
    background-color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  @media (min-width: 1366px) {
    font-size: 32px;
    width: 100%;
    h1 {
      margin: 0;
      font-weight: 500;
    }
  }

  cursor: default;
}

.ticket-accordion-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ticket-accordion-item-title {
  color: #575b60;
  font-size: 14px;
}

.ticket-accordion-item-text {
  font-size: 14px;
}

.tickets-messages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: fit-content;

  @media (max-width: 768px) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #fff;
    padding-inline: 16px;
    padding-top: 16px;
  }
}

.tickets-messages-title {
  font-size: 15px;
  font-weight: 500;
}

.tickets-messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 60px;
}

.tickets-message-row {
  position: relative;
  width: 100%;
}

.tickets-message-avatar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8e9295;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaea;
  overflow: hidden;

  @media (max-width: 1366px) {
    width: 24px;
    height: 24px;
  }
}

.tickets-message-bubble {
  display: flex;
  flex-direction: column;
  background-color: #e9eaea;
  border-radius: 12px;
  height: fit-content;
  padding: 12px 16px;

  &::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 25px;
    background: transparent;
    background-attachment: fixed;
  }
}

.tickets-message-row.user-message {
  .tickets-message-avatar {
    left: 0;
  }

  .tickets-message-bubble {
    margin-left: 47px;
    margin-right: 24px;
    border-top-left-radius: 0;

    &::before {
      top: 0;
      left: 22px;
      border-top-right-radius: 15px;
      box-shadow: 15px 0 0 0 #e9eaea;

      @media (max-width: 1366px) {
        left: 15px;
      }
    }

    @media (max-width: 1366px) {
      margin-left: 40px;
      margin-right: 16px;
    }
  }
}

.tickets-message-row.manager-message {
  .tickets-message-avatar {
    right: 0;
  }

  .tickets-message-bubble {
    margin-right: 47px;
    margin-left: 24px;
    border-top-right-radius: 0;

    &::before {
      top: 0;
      right: 22px;
      border-top-left-radius: 15px;
      box-shadow: -15px 0 0 0 #e9eaea;

      @media (max-width: 1366px) {
        right: 15px;
      }
    }

    @media (max-width: 1366px) {
      margin-right: 40px;
      margin-left: 16px;
    }
  }
}

.tickets-message-date-divider,
.tickets-message-name,
.tickets-message-date {
  color: #8e9295;
}

.tickets-message-date {
  text-align: right;
}

.tickets-message-date-divider {
  text-align: center;
}

.tickets-message-avatar-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.add-comment-input-wrapper {
  position: fixed;
  bottom: 20px;
  left: calc((100% - 320px) / 2);
  width: 600px;
  z-index: 13;

  &::before {
    content: '';
    width: 100%;
    height: 150px;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #fff;

    @media (max-width: 1366px) {
      top: -20px;
      left: -16px;
      border-radius: 16px;
      box-shadow: 0 2px 12px 0 #00000014;
      width: calc(100% + 32px);
    }
  }

  @media (max-width: 1366px) {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
  }
}

.tickets-message-text {
  word-wrap: break-word;
}

.add-comment-input {
  width: 100%;
  resize: none;
  overflow: hidden;
  border-radius: 8px;
  padding: 15px;
  font-size: 14px;
  border: none;
  z-index: 15;
  background-color: #f4f4f4;
  position: relative;
  font-family: Commissioner, sans-serif;
  padding-right: 60px;
  border: 1px solid #fff;
  transition: background-color 0.35s ease, border-color 0.35s ease;

  &:focus {
    border: 1px solid #c8c9ca;
    background-color: #fafafa;
    &::placeholder {
      color: #c8c9ca;
    }
  }
}

.send-comment-button-mobile {
  position: absolute;
  z-index: 16;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  background: none;

  @media (min-width: 768px) {
    display: none;
  }
}

.ticket-modal-subtitle {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 15px;
}

.ticket-modal-files-text {
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
}
