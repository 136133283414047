.registration {
  border: 2px solid #d5dada;
  border-radius: 12px;
  padding: 110px 50px 50px 50px;
  width: 800px;
  @media (max-width: 1366px) {
    max-width: 700px;
    margin: 88px auto 88px auto;
    padding: 110px 40px 50px;
  }

  @media (max-width: 747px) {
    max-width: none;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0 20px 32px;
  }

  &__personal-data {
    min-height: 420px;
    @media (max-width: 1366px) {
      display: flex;
    }
    @media (max-width: 747px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__user-info {
    display: inline-block;
    margin-right: 50px;
    vertical-align: top;
    width: 325px;
    @media (max-width: 1366px) {
      margin-right: 30px;
    }
    @media (max-width: 747px) {
      margin-right: 0;
      max-width: 375px;
      width: 100%;
    }
  }

  &__advantages {
    border-left: 2px solid #d5dada;
    display: inline-block;
    padding: 75px 0 75px 45px;
    vertical-align: top;
    width: 320px;
    @media (max-width: 1366px) {
      padding-left: 30px;
    }
    @media (max-width: 747px) {
      max-width: 375px;
      width: 100%;
      border-left: none;
      border-top: 2px solid #d5dada;
      margin-top: 32px;
      padding: 32px 0 0;
    }
  }

  &__advantages-title {
    color: #212325;
    font-size: 16px;
    font-weight: $font--medium;
    line-height: 1.5;
    margin: 0 0 20px;
    @media (max-width: 1366px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
      white-space: nowrap;
    }
  }

  &__advantages-description {
    font-size: 14px;
    font-weight: $font--light;
    line-height: 1.7142857;
    margin: 0 0 15px;
  }

  &__advantages-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__advantages-item {
    margin-bottom: 15px;
    padding-left: 14px;
    position: relative;

    &::after {
      background-color: #212325;
      border-radius: 50%;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 8px;
      width: 2px;
    }
  }

  &__final-data {
    text-align: center;
    @media (max-width: 747px) {
      text-align: left;
    }
  }

  &__final-data-title {
    color: $color-secondary;
    font-size: 16px;
    font-weight: $font--medium;
    line-height: 1.5;
    margin: 0;
  }

  &__final-data-personal {
    margin: 0 0 15px;
    text-transform: none;
  }

  &__final-data-description {
    font-size: 14px;
    font-weight: $font--light;
    line-height: 1.7142857;
    margin: 0 0 20px;
  }

  &__final-data-list {
    list-style: none;
    margin: 0;
    color: $color-brand;
    font-size: 20px;
    font-weight: $font--medium;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &__final-data-notice {
    color: $color-secondary;
    font-size: 16px;
    font-weight: $font--medium;
    margin: 0 0 20px;
  }

  &__final-data-mail {
    font-size: 16px;
    font-weight: $font--medium;
    color: $color-brand;
  }

  .page__title {
    @media (max-width: 747px) {
      margin-bottom: 24px;
    }
  }
}

.process-error {
  @media (max-width: 1366px) {
    padding: 100px;
    & .registration__final-data-notice {
      margin: 0;
    }
  }
  @media (max-width: 747px) {
    padding-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
    & .registration__final-data-notice {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      span {
        display: block;
        margin-top: 20px;
      }
    }
  }
}
