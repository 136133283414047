.custom-scrollbar {
  &__track-vertical {
    top: 3px;
    bottom: 3px;
    right: 4px;
    width: 5px !important;
    background-color: transparent;
  }

  &__thumb-vertical {
    width: 100%;
    border-radius: 3px;
    transition: background-color 0.2s ease-out;
    background-color: $color-light-grey;

    &:hover {
      cursor: pointer;
      background-color: $color-dark-grey;
    }
  }
}
