.location-picker-mobile {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 16px;
  background: #ffffff;
  border: 1px solid rgba(85, 85, 85, 0.2);
  border-radius: 3px;
  position: relative;
  transform: none;
  transition: transform 0.3s;

  &:after {
    content: '\A';
    border-style: solid;
    border-width: 7px 10px 7px 0;
    border-color: transparent #f05e4b transparent transparent;
    position: absolute;
    right: 16px;
    transform: rotate(270deg);
    transition: transform 0.3s;
  }

  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #f15a4f;
    text-transform: uppercase;
  }

  &__list {
    display: none;
    position: absolute;
    top: 46px;
    left: 0;
    padding: 4px 0;
    background: #ffffff;
    border: 1px solid rgba(85, 85, 85, 0.2);
    box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 100%;
    margin: 0;
    &_visible {
      display: block;
      z-index: 5;
    }
  }

  &__rotate-icon {
    &:after {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }

  &__item {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    & > button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #323754;
      transition: color 0.3s;
    }
    transition: background 0.3s;
    &:hover {
      //color: #323754;
      background: #e0ebfd;
      transition: background 0.3s;
      & > button {
        color: #323754;
        transition: color 0.3s;
      }
    }
  }

  .active {
    background: #73c9c4;
    & > button {
      color: #ffffff;
    }
  }
}
