.btn {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $font-stack-base;
  font-size: 18px;
  font-weight: $font--medium;
  line-height: 1.42857;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: $transition--default;
  vertical-align: middle;

  &:disabled {
    opacity: 0.85;
    cursor: initial;
  }

  &--ghost {
    background-color: transparent;
    border: 1px solid $color-red;
    border-radius: 3px;
    color: $color-red;
    fill: $color-red;
    padding: 7px 27px 6px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-red;
      color: $color-white;
    }
  }

  &--solid {
    background-color: $color-red;
    border: 1px solid $color-red;
    border-radius: 3px;
    color: $color-white;
    fill: $color-white;
    padding: 7px 27px 6px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: #ff6054;
    }
  }

  &--mini {
    background-color: $color-red;
    border: 1px solid $color-red;
    border-radius: 7px;
    color: $color-white;
    fill: $color-white;
    padding: 7px 27px 7px;
    font-size: 12px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: #ff6054;
    }
  }

  &--visa {
    background-repeat: no-repeat;
    padding: 5px 10px 3px;
    background-color: $color-red;
    border: 1px solid $color-red;
    border-radius: 7px;
    color: $color-white;
    fill: $color-white;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: #ff6054;
    }
  }

  &--with-arrow {
    padding: 7px 38px 6px 27px;

    &::after {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%209%2015%22%20width%3D%229px%22%20height%3D%2215px%22%20fill%3D%22%23fff%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M8.735%2C7.390%20C8.735%2C7.675%208.626%2C7.961%208.411%2C8.178%20L2.555%2C14.074%20C2.123%2C14.509%201.423%2C14.509%200.990%2C14.074%20C0.559%2C13.639%200.559%2C12.933%200.990%2C12.498%20L6.063%2C7.390%20L0.990%2C2.282%20C0.559%2C1.846%200.559%2C1.142%200.990%2C0.706%20C1.423%2C0.271%202.123%2C0.271%202.555%2C0.706%20L8.411%2C6.602%20C8.626%2C6.820%208.735%2C7.105%208.735%2C7.390%20Z%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      content: '';
      height: 15px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
    }
  }

  &--sm {
    font-size: 13px;
    line-height: 1.6;
    padding: 5px 20px;
  }

  &--md {
    font-size: 16px;
    line-height: 1.3;
  }

  &--with-preloader {
    & > div {
      margin-bottom: -7px;
    }
  }

  &--success {
    background-color: $color-light-turquoise;
    border: 1px solid $color-light-turquoise;
    color: $color-white;
    fill: $color-white;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: #6ebdb9;
    }
  }

  &--width-200 {
    width: 200px;
  }
}
