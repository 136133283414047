.workplace-picker {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__description {
    color: #555;
    font-size: 18px;
    font-weight: $font--medium;
    line-height: 1.2777777;
    margin: 0 0 5px;
  }

  &__info-openspace {
    margin: 0 0 25px;
    color: $color-new-gray;
    font-size: 13px;
    line-height: 24px;
    display: inline-block;
  }

  &__highlighter-btn {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2013%208%22%20width%3D%2213px%22%20height%3D%228px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M6.276%2C7.046%20C6.032%2C7.046%205.789%2C6.954%205.601%2C6.769%20L0.562%2C1.765%20C0.190%2C1.395%200.190%2C0.797%200.562%2C0.427%20C0.933%2C0.058%201.538%2C0.058%201.908%2C0.427%20L6.276%2C4.763%20L10.641%2C0.427%20C11.013%2C0.058%2011.616%2C0.058%2011.988%2C0.427%20C12.361%2C0.797%2012.361%2C1.395%2011.988%2C1.765%20L6.950%2C6.769%20C6.764%2C6.954%206.520%2C7.046%206.276%2C7.046%20Z%22/%3E%3C/svg%3E');
    background-position: 100% 10px;
    background-repeat: no-repeat;
    color: $color-red;
    font-size: 14px;
    font-weight: $font--medium;
    height: 31px;
    line-height: $line-height--base;
    padding: 5px 25px 7px 5px;
    text-transform: uppercase;

    &:hover {
      color: #ff6054;
    }
  }

  &__highlighter {
    position: relative;
  }

  &__search-input {
    display: none;
    position: absolute;
    top: 35px;
    width: 180px;

    .workplace-picker__highlighter.active & {
      display: block;
    }
  }

  &__search-input-field {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M15.940%2C14.207%20L12.806%2C11.060%20C13.679%2C9.868%2014.153%2C8.422%2014.153%2C6.947%20C14.153%2C3.121%2011.063%2C0.007%207.261%2C0.001%20C7.258%2C0.001%207.255%2C0.001%207.251%2C0.001%20L7.250%2C0.001%20C3.445%2C0.001%200.347%2C3.117%200.347%2C6.947%20C0.347%2C10.777%203.445%2C13.893%207.250%2C13.893%20C8.721%2C13.893%2010.158%2C13.416%2011.339%2C12.544%20L14.459%2C15.690%20C14.657%2C15.889%2014.920%2C15.999%2015.201%2C15.999%20C15.480%2C15.999%2015.743%2C15.889%2015.940%2C15.691%20C16.348%2C15.282%2016.348%2C14.616%2015.940%2C14.207%20ZM7.250%2C11.788%20C4.598%2C11.788%202.439%2C9.617%202.439%2C6.947%20C2.439%2C4.278%204.598%2C2.106%207.250%2C2.106%20C9.904%2C2.106%2012.062%2C4.278%2012.062%2C6.947%20C12.062%2C9.617%209.904%2C11.788%207.250%2C11.788%20ZM15.201%2C15.578%20C15.322%2C15.578%2015.442%2C15.543%2015.546%2C15.474%20C15.442%2C15.543%2015.322%2C15.578%2015.201%2C15.578%20Z%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: 9px 50%;
    padding: 19px 15px 19px 35px;

    &:focus {
      border-color: rgba(85, 85, 85, 0.2);
    }
  }
}
