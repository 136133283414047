.link {
    color: $color-red;
    font-weight: $font--medium;
    text-decoration: none;
    transition: color 0.2s ease-out;

    :root:not(.mobile) &:hover {
      // color: #e64236;
    }
}
