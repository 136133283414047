.workplace-reservation {
  min-width: 970px;
  padding: 35px 40px 25px 40px;
  @media (max-width: 970px) {
    padding: 65px;
    min-width: 100%;
  }

  @media (max-width: 747px) {
    padding: 24px 20px;
  }
  @media (min-width: 1440px) and (max-width: 2000px) {
    margin: 0 20px 24px;
  }

  &__title {
    @media (max-width: 747px) {
      margin: 0 20px 24px;
    }
    @media (min-width: 1440px) {
      margin: 0 20px 24px;
    }
  }

  &__location-picker {
    margin-bottom: 35px;
    @media (max-width: 747px) {
      margin-bottom: 24px;
    }
  }

  &__floor-plan {
    &-content {
      position: relative;
    }

    &-loader {
      display: none;
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--with-preloader > &-content {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #fff;
      }
    }
    &--with-preloader > &-loader {
      display: block;
    }
  }

  &__booking-modal {
    @media (max-width: 1366px) {
      max-width: 450px;
      left: 50% !important;
      transform: translate(-50%) !important;
    }
    @media (max-width: 747px) {
      max-width: 100%;
      left: auto;
      transform: none;
    }
  }

  &__list-container {
    @media (max-width: 747px) {
      width: 100%;
      background: #fff;
      border: 1px solid rgba(85, 85, 85, 0.2);
      border-radius: 3px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      padding-right: 24px;

      &::after {
        content: '\A';
        border-style: solid;
        border-width: 7px 10px 7px 0;
        border-color: transparent #f05e4b transparent transparent;
        position: absolute;
        right: 16px;
        transform: rotate(270deg);
        transition: transform 0.3s;
      }
    }
  }
  &__section-list {
    list-style: none;
    margin: 0 0 34px;
    padding: 0;
    @media (max-width: 747px) {
      display: none;
    }
  }

  &__section-item {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 12px;
      @media (max-width: 747px) {
        margin-left: 0;
      }
    }

    @media (max-width: 747px) {
      width: 100%;
    }
  }

  &__section-btn {
    font-size: 16px;
    line-height: 1.2;
    padding: 11px 27px 10px;
    text-transform: uppercase;
    @media (max-width: 747px) {
      width: 100%;
      padding: 6px;
      background-color: transparent;
      border-radius: 0;
      border: none;
      color: #323754;
      font-size: 14px;
      line-height: 20px;
      &:hover:not(:disabled) {
        background-color: #e0ebfd;
        color: #323754;
      }
    }
  }

  &__list-label-style {
    display: none;
    @media (max-width: 747px) {
      display: initial;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #f15a4f;
    }
  }

  .active-item {
    @media (max-width: 747px) {
      background: #73c9c4;
      color: #fff;
    }
  }

  .visible-list {
    @media (max-width: 747px) {
      position: absolute;
      width: 100%;
      left: 0;
      top: 44px;
      z-index: 10;
      background-color: #fff;
      margin: 0;
      border: 1px solid rgba(85, 85, 85, 0.2);
      box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .rotate-icon {
    &::after {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
}
