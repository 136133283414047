.checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &__input {
    left: -9999px;
    position: absolute;
  }

  &__label {
    color: $color-red;
    text-align: left;
    cursor: default;

    .checkbox__input:checked + &,
    .checkbox__input:not(:checked) + & {
      cursor: pointer;
      display: inline-block;
      font-family: $font-stack-base;
      font-size: 16px;
      font-weight: $font--light;
      line-height: 1.5384615;
      padding-left: 27px;
      position: relative;
      vertical-align: middle;

      .shooting__form-checkbox & {
        font-weight: $font--medium;
        padding-left: 23px;
      }

      @media (max-width: 1366px) {
        padding-left: 30px;
        margin-top: 10px;
      }
    }

    .checkbox__input:checked + &::before,
    .checkbox__input:not(:checked) + &::before {
      background-color: transparent;
      border: 1px solid $color-red;
      border-radius: 0;
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: 50%;
      transition: $transition--default;
      width: 12px;
      transform: translateY(-50%);
      @media (max-width: 1366px) {
        width: 24px;
        height: 24px;
      }
    }

    .checkbox__input:checked + &::after,
    .checkbox__input:not(:checked) + &::after {
      border-bottom: 2px solid $color-red;
      border-left: 2px solid $color-red;
      content: '';
      height: 5px;
      left: 2px;
      position: absolute;
      top: 6px;
      transition: $transition--default;
      width: 8px;
      @media (max-width: 1366px) {
        width: 16px;
        height: 10px;
        top: 1px;
        left: 4px;
      }
    }

    .checkbox__input:not(:checked) + &::after {
      opacity: 0;
      transform: rotate(-45deg) scale(0);
    }

    .checkbox__input:checked + &::after {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }
  }
  &__caption {
    color: $color-dark-grey;
    padding-top: 5px;
    font-size: 12px;
  }

  &__link {
    color: $color-red;
    font-weight: $font--medium;
    text-decoration: none;
  }
}
