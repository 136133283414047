.print {
  width: 970px;
  @media (max-width: 1366px) {
    width: 100%;
  }

  &__section {
    margin-bottom: 30px;
    padding: 17px 34px 17px 33px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
    @media (max-width: 1366px) {
      padding: 40px;
    }
    @media (max-width: 747px) {
      padding: 24px 20px;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    @media (max-width: 1366px) {
      max-height: 120px;
    }
    @media (max-width: 747px) {
      flex-direction: column;
      max-height: none;
    }
  }

  &__file-form {
    display: flex;
    @media (max-width: 747px) {
      flex-direction: column;
      width: 100%;
      margin: 0 -20px;
    }
  }

  &__file-title {
    width: 270px;
    padding-right: 28px;
    line-height: 1.3;
    color: $color-brand;
    @media (max-width: 1366px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: auto;
      padding-right: 16px;
    }
    @media (max-width: 747px) {
      padding-right: 0;
      align-self: flex-start;
      margin-bottom: 16px;
    }
  }

  &__history-title {
    @media (max-width: 1366px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  &__file-load {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    margin-top: -17px;
    margin-bottom: -17px;
    background-color: #fdebe0;
    z-index: 2;
    transition: background-color 0.7s ease;

    &--accept {
      background-color: #f3ece7;
    }
    @media (max-width: 1366px) {
      width: auto;
      max-width: 300px;
      height: 120px;
    }
    @media (max-width: 747px) {
      margin: 0 -20px;
      width: calc(100% + 40px);
      max-width: none;
      margin-bottom: 24px;
    }
  }

  &__file-load-input {
    width: 100%;
  }

  &__file-text {
    font-weight: $font--medium;
    text-align: center;
    color: #f15a4f;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: linear-gradient(90deg, #fabd99 100%, transparent 100%) top left no-repeat,
        linear-gradient(90deg, transparent 4px, #fabd99 4px, #fabd99 12px, transparent 12px) top center repeat-x,
        linear-gradient(90deg, #fabd99 100%, transparent 100%) top right no-repeat,
        linear-gradient(0deg, #fabd99 100%, transparent 100%) top left no-repeat,
        linear-gradient(0deg, transparent 4px, #fabd99 4px, #fabd99 12px, transparent 12px) center left repeat-y,
        linear-gradient(0deg, #fabd99 100%, transparent 100%) bottom left no-repeat,
        linear-gradient(90deg, #fabd99 100%, transparent 100%) bottom left no-repeat,
        linear-gradient(90deg, transparent 4px, #fabd99 4px, #fabd99 12px, transparent 12px) bottom center repeat-x,
        linear-gradient(90deg, #fabd99 100%, transparent 100%) bottom right no-repeat,
        linear-gradient(0deg, #fabd99 100%, transparent 100%) top right no-repeat,
        linear-gradient(0deg, transparent 4px, #fabd99 4px, #fabd99 12px, transparent 12px) center right repeat-y,
        linear-gradient(0deg, #fabd99 100%, transparent 100%) bottom right no-repeat;
      background-size: 0 4px, 25.8px 2px, 0 0, 0 0, 2px 21px, 0 0;
    }

    .print__file-load.is-empty & {
      font-weight: 300;
      white-space: pre;
      overflow: visible;
      text-overflow: inherit;
    }
    @media (max-width: 1366px) {
      //font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 0 31px;
    }
    @media (max-width: 747px) {
      padding: 0;
    }
  }

  &__file-text-bold {
    font-weight: $font--medium;
  }

  &__file-btn {
    width: 102px;
    padding-left: 24px;
    padding-right: 22px;
    margin-left: 31px;
    @media (max-width: 1366px) {
      width: 146px;
      height: 40px;
      font-size: 16px;
      line-height: 24px;
      align-self: center;
    }
    @media (max-width: 747px) {
      margin-left: 0;
      width: 280px;
    }
  }

  &__current-package {
    padding-top: 28px;
    padding-bottom: 31px;
    @media (max-width: 1366px) {
      padding: 40px;
    }
    @media (max-width: 747px) {
      padding: 24px 20px;
    }
  }

  &__current-package-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__current-package-title {
    margin-right: 11px;
    color: $color-light-turquoise;
  }

  &__current-package-hint {
    font-size: 12px;
    font-weight: $font--medium;
    line-height: 1.1;
    color: $color-dark-grey;
  }

  &__current-package-footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: $font--medium;
    line-height: 1.1;
    color: #555;
    @media (max-width: 747px) {
      flex-direction: column;
      align-items: flex-start;
      height: 72px;
    }
  }

  &__current-package-remainder-count {
    margin-left: 8px;
    font-size: 16px;
    line-height: 1.44;
  }

  &__current-package-pin {
    display: flex;
    align-items: baseline;
  }

  &__current-package-pin-number {
    margin-right: 9px;
    margin-left: 6px;
    font-size: 16px;
    line-height: 1.44;
    color: $color-light-turquoise;
  }

  &__current-package-btn {
    cursor: pointer;
  }

  &__packages-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    @media (max-width: 1366px) {
      justify-content: center;
    }
    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__packages-item {
    padding-top: 28px;
    padding-bottom: 31px;
    margin-left: 0;
    @media (max-width: 1366px) {
      width: 220px;
      padding: 24px 16px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 747px) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__history {
    padding-top: 28px;
    padding-bottom: 31px;
  }

  &__history-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    //@media (max-width: 1366px) {
    //  margin: 0 0 16px;
    //}
  }

  &__history-list {
    display: flex;
    margin-top: 18px;
    margin-bottom: 20px;
    @media (max-width: 1366px) {
      flex-direction: column;
    }
  }

  &__history-item {
    width: 404px;
    //  margin-right: 50px;
    margin-left: 40px;

    &:last-child {
      width: 350px;
      margin-right: 0;
    }
    @media (max-width: 1366px) {
      width: 100%;
      margin: 0 0 16px;
      &:last-child {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__history-table-header-mobile-container {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      .print__history-table-head {
        display: flex;
        width: 100%;
        .print__history-table-row--head {
          width: 100%;
          display: flex;
        }
      }
    }
  }

  &__history-table-head {
    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__history-table-cell {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
    font-weight: $font--medium;
    line-height: 23px;
    color: $color-dark-grey;

    &:last-child {
      width: 150px;
      padding-right: 30px;
      @media (max-width: 1366px) {
        width: auto;
        padding-right: 0;
      }

      //.print__history-item:last-child & {
      //    width: 124px;
      //}
    }

    &:first-child {
      width: 270px;
      padding-right: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-red;

      //.print__history-item:last-child & {
      //    width: 224px;
      //}
      @media (max-width: 1366px) {
        width: 222px;
      }
      @media (max-width: 747px) {
        width: 122px;
      }
    }

    &--head {
      padding-bottom: 8px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1;
      text-align: left;
      text-transform: uppercase;

      &:first-child {
        color: $color-dark-grey;
      }
    }
  }

  &__history-btn-show-more {
    display: block;
    width: 152px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5.5px;
    padding-bottom: 4.5px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 7px;
  }
}
