.Toastify__toast {
  font-family: $font-stack-base;
  font-size: $font--page;
  font-weight: $font--medium;
  border-radius: 4px;

  &--info {
    background: $color-brand;
  }

  &--success {
    background: $color-light-turquoise;
  }

  &--warning {
    background: $color-light-green;
  }

  &--error {
    background: $workki-red-light;
  }
}
