.notification {
    width: 100%;
    padding: 13px 15px 12px 52px;
    font-size: 14px;
    color: #555;
    border-radius: 3px;
    background-color: rgba(250, 189, 153, 0.3);
    background-repeat: no-repeat;

    &__text {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 16px;
    }

    &--error {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2017%2017%22%20width%3D%2217px%22%20height%3D%2217px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M16.192%2C12.306%20C17.043%2C13.185%2017.043%2C14.562%2016.192%2C15.411%20C15.313%2C16.289%2013.933%2C16.289%2013.083%2C15.411%20L8.800%2C11.164%20L4.547%2C15.411%20C3.695%2C16.289%202.287%2C16.289%201.437%2C15.411%20C0.586%2C14.562%200.586%2C13.185%201.437%2C12.306%20L5.690%2C8.060%20L1.436%2C3.813%20C0.586%2C2.934%200.586%2C1.557%201.436%2C0.708%20C2.287%2C-0.170%203.695%2C-0.170%204.546%2C0.708%20L8.800%2C4.955%20L13.083%2C0.708%20C13.933%2C-0.170%2015.312%2C-0.170%2016.191%2C0.708%20C17.042%2C1.558%2017.042%2C2.934%2016.191%2C3.813%20L11.909%2C8.060%20L16.192%2C12.306%20Z%22/%3E%3C/svg%3E');
        background-position: 17px 49%;
        background-size: 2% 17px;
    }

    &--warning {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%209%2021%22%20width%3D%229px%22%20height%3D%2221px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M7.364%2C4.539%20C7.854%2C4.080%208.099%2C3.523%208.099%2C2.869%20C8.099%2C2.217%207.854%2C1.660%207.364%2C1.196%20C6.875%2C0.733%206.286%2C0.500%205.596%2C0.500%20C4.910%2C0.500%204.315%2C0.733%203.823%2C1.196%20C3.333%2C1.660%203.085%2C2.217%203.085%2C2.869%20C3.085%2C3.523%203.333%2C4.080%203.823%2C4.539%20C4.315%2C4.998%204.910%2C5.229%205.596%2C5.229%20C6.286%2C5.229%206.875%2C4.998%207.364%2C4.539%20ZM6.236%2C19.887%20C7.119%2C19.471%207.587%2C18.688%207.127%2C18.330%20C6.864%2C18.124%206.517%2C18.465%206.263%2C18.465%20C5.720%2C18.465%205.337%2C18.375%205.118%2C18.195%20C4.895%2C18.013%204.788%2C17.676%204.788%2C17.178%20C4.788%2C16.980%204.820%2C16.688%204.887%2C16.303%20C4.954%2C15.917%205.033%2C15.571%205.118%2C15.270%20L6.168%2C11.523%20C6.266%2C11.179%206.339%2C10.801%206.379%2C10.390%20C6.415%2C9.975%206.435%2C9.688%206.435%2C9.526%20C6.435%2C8.737%206.160%2C8.095%205.611%2C7.600%20C5.064%2C7.107%204.282%2C6.858%203.268%2C6.858%20C2.705%2C6.858%202.105%2C6.942%201.478%2C7.161%20C0.246%2C7.596%200.520%2C8.676%200.793%2C8.676%20C1.348%2C8.676%201.720%2C8.771%201.916%2C8.960%20C2.113%2C9.148%202.214%2C9.482%202.214%2C9.964%20C2.214%2C10.228%202.179%2C10.526%202.116%2C10.847%20C2.052%2C11.168%201.976%2C11.510%201.881%2C11.870%20L0.825%2C15.631%20C0.735%2C16.026%200.667%2C16.380%200.622%2C16.694%20C0.579%2C17.008%200.560%2C17.315%200.560%2C17.616%20C0.560%2C18.388%200.842%2C19.025%201.407%2C19.528%20C1.973%2C20.032%202.842%2C20.500%203.862%2C20.500%20C4.524%2C20.500%205.470%2C20.252%206.236%2C19.887%20Z%22/%3E%3C/svg%3E');
        background-position: 23px 10px;
        background-size: 1% 21px;
    }
}
