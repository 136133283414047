.multi-input {
  position: relative;
  width: 100%;

  &__list {
    position: relative;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 5px 5px 42px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(85, 85, 85, 0.2);
    border-radius: 3px;

    &--empty {
      padding: 0 0 36px;
    }
  }

  &__list-item {
    font-family: Rubik, Arial, sans-serif;
    font-size: 14px;
    color: #555;
    line-height: 1;
    font-weight: 500;
    margin: 5px;
    padding: 5px 5px;
    background-color: #ccc;
    border-radius: 3px;
  }

  & > input {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &__close {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 10px;
    cursor: pointer;
  }
}
