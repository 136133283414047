.main-menu {
  margin-bottom: auto;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .accordion__body {
    padding-bottom: 0;
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    overflow: hidden;
  }

  &__item {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 18px;
    font-weight: $font--medium;
    line-height: 1.1111111;
    margin-bottom: 25px;
    transition: $transition--default;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: $font--medium;
    line-height: 1.1111111;
    margin-bottom: 25px;
    text-decoration: none;
    transition: $transition--default;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    .main-menu__item.active &,
    .main-menu__item.active:hover & {
      color: $color-red;
    }
  }

  &__sub-menu {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    height: 0;
    list-style: none;
    margin-top: 0;
    opacity: 0;
    padding-left: 20px;
    pointer-events: none;
    position: relative;
    transition: $transition--default;

    .main-menu__item.active & {
      height: auto;
      margin-top: 15px;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__sub-item {
    font-size: 16px;
    font-weight: $font--light;
    line-height: 1.25;
    margin-bottom: 15px;
    transition: $transition--default;
  }

  &__sub-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    &.active {
      color: $color-red;
      font-weight: $font--medium;

      &:hover {
        color: $color-red;
      }
    }
  }

  &__custom-scrollbar {
    .custom-scrollbar__thumb-vertical {
      background-color: $color-red;

      &:hover {
        background-color: $color-brand;
      }
    }
  }
}
