.title {
    margin: 0;
    font-family: $font-stack-base;
    font-weight: $font--medium;
    color: $color-brand;

    &--h2 {
        color: $color-dark-grey;
        font-size: 18px;
        line-height: 1.1;
    }
}
