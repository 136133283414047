.profile-avatar {
  display: flex;
  align-items: center;
  gap: 32px;
  width: min-content;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 400;

  &--empty {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0;
    &::after {
      content: 'No logo';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-light-grey;
      font-size: 11px;
      font-weight: $font--bold;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-width: 0;
    font-size: 12px;
    font-weight: $font--medium;
    line-height: 16px;
    text-align: center;
    color: $color-red;
    background-color: #feeeed;
    transition: background-color 0.2s ease-out;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background-color: #f9f2f1;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__action {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: none;
    flex-shrink: 0;

    .profile-avatar:hover & {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }

    button {
      height: 40px;
      width: 40px;
      padding: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: unset;
      border: unset;
      border-radius: 50%;
      background: $workki-light-light1;
      &:hover {
        background: $workki-light-light2;
      }
    }
  }
}
