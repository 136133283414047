.search {
  width: 46px;
  height: 16px;
  align-self: flex-start;
  margin-left: auto;
  @media (max-width: 1366px) {
    height: 41px;
    width: 100%;
    position: relative;
  }

  &__btn-group {
    width: 100%;
    padding-top: 6px;
    display: flex;
    opacity: 1;
    justify-content: space-between;
    transition: opacity 0.4s ease-in;

    .search.active & {
      opacity: 0;
      transition: none;
    }
  }

  &__btn {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__reset-btn {
    background-color: transparent;
    border-color: transparent;
    font-size: 0;
    position: relative;
    width: 22px;

    .search.active & {
      // pointer-events: auto;
    }

    &::before,
    &::after {
      background-color: $color-red;
      content: '';
      height: 2px;
      position: absolute;
      right: 7px;
      transition: $transition--default;
      width: 15px;
      top: 7px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__icon-field {
    position: absolute;
    top: 14px;
    left: 9px;
    z-index: 1;
    width: 16px;
    height: 16px;
    transform: translateX(740px);
    transition: transform 0.23s ease-out;
    opacity: 0;

    .search.active & {
      left: 10px;
      opacity: 1;
      transform: translateX(0);
      transition-duration: 0.4s;
    }
  }

  &__wrap-field {
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 805px;
    height: 42px;
    overflow: hidden;
    pointer-events: none;
    @media (max-width: 1366px) {
      width: 100%;
    }

    .search.active & {
      pointer-events: auto;
    }
  }

  &__field {
    width: 100%;
    padding: 10px 32px 9px 40px;
    font-family: $font-stack-base;
    font-size: 17px;
    font-weight: $font--light;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $color-white;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.4s ease-out, border-color 0.6s ease-in, opacity 0.4s ease-in;

    .search.active & {
      opacity: 1;
      transform: translateX(0);
      border-color: $color-light-grey;
    }
  }

  &__close-btn {
    background-color: transparent;
    border-color: transparent;
    display: none;
    font-size: 0;
    height: 100%;
    pointer-events: none;
    position: relative;
    width: 18px;
    margin-left: auto;
    margin-right: 5px;

    .search.active & {
      pointer-events: auto;
      display: block;
    }

    &::before,
    &::after {
      background-color: $color-red;
      content: '';
      height: 2px;
      position: absolute;
      right: 2px;
      transition: $transition--default;
      width: 15px;
      top: 21px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
