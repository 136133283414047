.sidebar {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 40px 0 30px 40px;
    height: 100%;

    &__logo {
        display: inline-block;
        height: 24px;
        margin-bottom: 45px;
        width: 137px;

        & img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    &__support.btn {
        margin-bottom: 30px;
        margin-top: 20px;
    }

    &__enter-btn.btn {
        margin-top: auto;
    }
}
