.profile-contracts {
  padding-top: 20px;

  .contract-item {
    display: grid;
    width: 300px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(85, 85, 85, 0.2);
    overflow: hidden;
    cursor: pointer;
    grid-template-areas: 'image content' 'image description';
    grid-template-columns: 30px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
    border: 1px solid #f15a4f;

    &__image {
      grid-area: image;
    }

    &__title {
      font-weight: bold;
      font-size: 14px;
      grid-area: content;
      color: #323754;
    }

    &:hover &__title {
      color: #f15a4f;
    }

    &__description {
      grid-area: description;
    }
  }
  .sublease-contracts-table {
    margin-top: 30px;
  }

  &__download-button {
    color: #f15a4f;
  }
}
