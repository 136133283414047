.profile-logins {
  margin-left: -35px;
  margin-right: -35px;
  padding-top: 10px;
  text-align: center;
  @media (max-width: 1366px) {
    margin-left: -40px;
    margin-right: -40px;
  }
  @media (max-width: 747px) {
    margin-left: -20px;
    margin-right: -20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__table-container {
    overflow-x: auto;
    width: 100%;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 30px;
    border-collapse: collapse;
  }

  &__table-row {
    height: 26px;
    @media (max-width: 1366px) {
      height: 48px;
    }
    @media (max-width: 747px) {
      height: 40px;
    }

    &--head {
      height: 35px;
    }

    .profile-logins__table-body & {
      &:nth-of-type(2n) {
        background-color: #f6f6f6;
      }
    }
  }

  &__table-cell {
    padding-left: 35px;
    font-size: 14px;
    font-weight: $font--medium;
    line-height: 1.8;
    color: $color-dark-grey;
    text-align: left;
    @media (max-width: 1366px) {
      padding-left: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      width: 150px;
      @media (max-width: 1366px) {
        width: 120px;
        padding-left: 40px;
      }
      @media (max-width: 747px) {
        padding-left: 20px;
      }
    }

    &:nth-of-type(2) {
      width: 240px;
      @media (max-width: 1366px) {
        width: 120px;
      }
    }

    &:nth-of-type(3) {
      width: 190px;
      @media (max-width: 1366px) {
        width: 140px;
      }
    }

    &:nth-of-type(4) {
      width: 160px;
      @media (max-width: 1366px) {
        width: 120px;
      }
    }

    &:last-of-type {
      width: 240px;
      @media (max-width: 1366px) {
        width: 185px;
        //padding-right: 40px;
      }
    }

    .profile-logins__table-row.not-active & {
      opacity: 0.5;
      pointer-events: none;
    }

    &--light-text {
      font-weight: $font--light;
      font-size: 12px;
    }

    &--head {
      font-size: 12px;
      font-weight: $font--light;
      text-transform: uppercase;
    }
  }

  &__table-btn {
    font-family: $font-stack-base;
    font-size: 12px;
    font-weight: $font--medium;
    color: $color-red;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &--without-font {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  &__table-btn-delimiter {
    padding-right: 9px;
    font-family: $font-stack-base;
    font-size: 12px;
    font-weight: $font--light;
    color: $color-dark-grey;
    position: relative;
    &::after {
      content: '|';
      position: absolute;
      top: -1px;
      right: 3px;
    }
  }

  &__table-hint {
    margin-left: 10px;
  }

  &__add-login-btn {
    min-width: 115px;
    border-radius: 6px;
    text-transform: lowercase;
    height: 26px;
    padding: 3px 20px;
    @media (max-width: 1366px) {
      height: 40px;
      width: 173px;
      border-radius: 3px;
    }

    @media (max-width: 747px) {
      width: calc(100% - 40px);
      margin: 0 20px;
    }
  }

  &-empty {
    padding: 20px 10px 10px;
    color: $color-dark-grey;
    font-weight: $font--normal;
    font-size: 13px;
    text-align: center;

    & span {
      color: $color-red;
      font-weight: $font--medium;
      cursor: pointer;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}
