.phone-password {
  font-size: inherit;
  justify-content: space-between;
  font-weight: $font--medium;
  color: $color-dark-grey;
  display: flex;
  align-items: center;
  max-width: 230px;

  &__password {
    margin-left: 5px;
    color: $color-red;
  }
  &__spinner {
    margin-left: 5px;
    padding-top: 5px;
  }
}
