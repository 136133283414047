.ticket-details {
  padding-right: 25px;
  padding-bottom: 20px;
  width: 970px;
  display: flex;
  flex-direction: column;
  min-height: 350px;

  &__header {
    font-weight: $font--light;
    color: $color-dark-grey;
    display: flex;
    flex-direction: column;
  }

  &__header-row {
    font-weight: $font--light;
    color: $color-dark-grey;
    display: flex;
    align-items: baseline;

    & + & {
      margin-top: 8px;
    }
  }

  &__status {
    margin-left: auto;
    font-weight: $font--medium;
    font-size: 16px;
    text-transform: uppercase;
    color: $color-brand;
  }

  &__assign {
    margin-left: auto;
    font-weight: $font--medium;
    font-size: 16px;
    text-transform: uppercase;
    color: $color-brand;
  }

  &__label {
    text-transform: none;
    padding-right: 6px;
    font-size: 14px;
    font-weight: $font--light;
    color: $color-dark-grey;
  }

  &__info {
    display: flex;
  }

  &__info-item {
    color: $color-brand;
    font-weight: $font--normal;
    &:not(:last-child) {
      padding-right: 20px;
    }
  }

  &__file-link {
    margin-left: auto;
    cursor: pointer;
  }

  &__description-container {
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  &__description {
    padding: 6px 20px 5px;
    flex-grow: 1;
    background-color: rgba(85, 85, 85, 0.03);
    border-radius: 8px;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
  }

  &__description-header {
    font-size: 12px;
    padding-bottom: 5px;
    font-weight: $font--normal;
    color: $color-light-grey;
    display: flex;
  }

  &__description-footer {
    font-size: 12px;
    font-weight: $font--normal;
    color: $color-light-grey;
    display: flex;
  }

  &__description-author {
    font-weight: $font--normal;
    color: $color-brand;
  }

  &__description-text {
    padding-bottom: 10px;
    word-break: break-word;
    font-weight: $font--light;
    color: $color-dark-grey;
  }

  &__comments {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: -25px;
  }

  &__comments-action {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    padding-right: 25px;
  }

  &__comments-action-link {
    position: relative;
    line-height: 1.1;
    border-radius: 6px;
    text-transform: lowercase;
    vertical-align: center;
    height: 26px;
    padding: 3px 20px 3px 30px;
    cursor: pointer;
  }

  &__comments-action-plus {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 15px;
    line-height: 1;
    font-weight: $font--heavy;
  }

  &__comments-scrollbar {
    flex-grow: 1;
  }

  &__comments-list {
    padding-right: 25px;
    overflow: visible;
  }

  &__comments-item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    padding: 6px 20px 15px;
    flex-grow: 1;
    background-color: rgba(85, 85, 85, 0.03);
    border-radius: 8px;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
  }

  &__comments-text {
    word-break: break-word;
    color: $color-dark-grey;
  }

  &__comments-info {
    font-size: 12px;
    padding-bottom: 5px;
    font-weight: $font--normal;
    color: $color-light-grey;
    display: flex;
  }

  &__author {
    color: $color-brand;
  }

  &__time {
    margin-left: auto;
  }

  @media (max-width: 1366px) {
    width: 100%;
    padding: 40px;
    &__description-container {
      padding-top: 24px;
      margin-bottom: 24px;
    }
    &__description {
      padding: 16px;
    }
    &__description-author {
      margin-bottom: 8px;
    }
    &__comments-action-link {
      height: 40px;
      border-radius: 3px;
    }
    &__comments-action-plus {
      font-size: 24px;
      font-weight: 300;
      top: 48%;
      left: 10px;
    }
    .table-data-loader {
      height: auto;
      span {
        margin-right: 12px;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__comments-scrollbar {
      margin-top: 60px;
    }
  }

  @media (max-width: 747px) {
    padding: 24px 20px;
    &__info {
      flex-direction: column;
    }
    &__info-item {
      padding-right: 0;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__description-header {
      flex-direction: column;
    }

    &__time {
      margin: 8px 0 16px 0;
    }
    &__comments-action-link {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__comments {
      margin-right: 0;
    }
    &__comments-action {
      padding-right: 0;
    }
    &__comments-action-plus {
      position: static;
      margin-right: 10px;
      margin-top: 24px;
    }
    &__description-container {
      margin-bottom: 16px;
    }
    &__comments-scrollbar {
      margin-top: 0;
    }
    .table-data-loader {
      flex-direction: column;
      span {
        margin-bottom: 8px;
        text-align: center;
      }
    }
    &__label {
      font-size: 12px;
    }
  }
}
