.location-picker {
  @media (max-width: 1366px) {
    position: relative;
    width: 100%;
  }
  &__list {
    display: inline-block;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
    @media (max-width: 1366px) {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      &::-webkit-scrollbar {
        background: none;
      }
    }
  }

  &__item {
    display: inline-block;
    margin-right: 5px;

    .location-picker--buttons & {
      margin-right: 17px;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1366px) {
      flex-shrink: 0;
      margin-right: 25px;
      button {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width: 747px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__btn {
    border-bottom: 2px solid transparent;
    color: #555;
    font-size: 14px;
    font-weight: $font--medium;
    line-height: $line-height--base;
    padding: 5px 10px;
    text-transform: uppercase;
    transition: $transition--default;

    .location-picker--buttons & {
      border-radius: 4px;
      border: 1px solid $color-red;
      color: $color-red;
      font-size: 16px;
      line-height: 1.3;
      padding: 11px 25px 10px;
    }

    .location-picker--sub-location & {
      padding: 5px 15px;
    }

    .location-picker__item.active & {
      border-bottom-color: $color-red;
      color: $color-red;

      .location-picker--buttons & {
        background-color: $color-red;
        color: $color-white;
      }
    }
  }
}

.profile-class {
  @media (max-width: 1366px) {
    & ul {
      &::after {
        content: '';
        height: 31px;
        width: 31px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(90deg, #fff 0%, rgba(0, 0, 0, 0) 50%);
      }

      &::before {
        content: '';
        height: 31px;
        width: 31px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #fff 50%);
      }
      & li:first-child {
        z-index: 10;
      }
      & li:last-child {
        z-index: 10;
      }
    }
  }
}
