.dashboard-services-card {
  @media (min-width: 1367px) {
    &:hover {
      background-color: $color-red;
      position: relative;
    }
  }

  &__not-workplaces {
    margin: auto 0;
    font-size: 18px;
    line-height: 1;
    font-weight: $font--medium;
    color: rgba($color-dark-grey, 0.9);
    cursor: default;

    &--link {
      color: $color-light-turquoise;
    }
    @media (max-width: 1366px) {
      max-width: 426px;
      margin: 0 auto;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 747px) {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__order-btn {
    display: none;
    position: absolute;
    font-size: 18px;
    font-weight: $font--medium;
    width: 100%;
    height: 100%;
    color: #fff;
    left: 0;
    top: 0;
    .dashboard-services-card:not(.is-active):hover & {
      display: block;
    }

    @media (max-width: 1366px) {
      color: transparent;
      span {
        font-size: 0;
        width: 0;
        height: 0;
      }
    }

    span {
      position: absolute;
      left: 60px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.is-active:hover {
    background-color: transparent;
  }

  &__title {
    margin: auto 0;
    font-size: 18px;
    line-height: 1;
    font-weight: $font--medium;
    color: rgba($color-dark-grey, 0.9);
    cursor: default;
    padding-right: 20px;

    @media (max-width: 1366px) {
      color: #f05e4b;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding-right: 0;
    }

    @media (max-width: 747px) {
      overflow-wrap: anywhere;
    }

    .dashboard-services-card:hover & {
      color: $color-red;
    }

    .dashboard-services-card.is-active & {
      color: $color-red;
    }

    .dashboard-services-card.is-active:hover & {
      color: #ff6054;
    }

    .dashboard-services-card.is-active.is-opened & {
      color: $color-light-turquoise;
    }

    @media (max-width: 747px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__short-info {
    display: flex;
    padding-left: 60px;
    padding-right: 20px;
    position: relative;
    height: 64px;
    align-items: center;
    @media (max-width: 747px) {
      padding-left: 35px;
      padding-right: 8px;
    }
  }

  &__more {
    padding: 0 20px 20px 20px;
    @media (max-width: 747px) {
      padding: 0 5px 8px;
    }
  }

  &__img-wrap {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    line-height: 0;
    @media (max-width: 747px) {
      left: 5px;
    }
  }

  &__icon {
    fill: rgba($color-dark-grey, 0.8);
    @media (max-width: 1366px) {
      fill: #f05e4b;
    }
    @media (min-width: 1367px) {
      .dashboard-services-card:hover & {
        fill: #fff;
      }
    }
    .dashboard-services-card:hover & {
      fill: #f05e4b;
    }

    .dashboard-services-card.is-active & {
      fill: $color-red;
    }

    .dashboard-services-card.is-active:hover & {
      fill: #ff6054;
    }

    .dashboard-services-card.is-active.is-opened & {
      fill: $color-light-turquoise;
    }
  }

  &__btn-more {
    background: transparent;
    font-size: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;

    &::after {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2011%207%22%20width%3D%2211px%22%20height%3D%227px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M5.276%2C6.599%20C5.064%2C6.599%204.852%2C6.519%204.691%2C6.358%20L0.308%2C2.005%20C-0.017%2C1.684%20-0.017%2C1.163%200.308%2C0.842%20C0.630%2C0.521%201.155%2C0.521%201.479%2C0.842%20L5.276%2C4.613%20L9.074%2C0.842%20C9.398%2C0.521%209.921%2C0.521%2010.245%2C0.842%20C10.568%2C1.163%2010.568%2C1.684%2010.245%2C2.005%20L5.862%2C6.358%20C5.700%2C6.519%205.489%2C6.599%205.276%2C6.599%20Z%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      content: '';
      height: 7px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: $transition--default;
      width: 11px;

      .dashboard-services-card.is-opened & {
        transform: rotate(-180deg) translateY(50%);
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2011%207%22%20width%3D%2211px%22%20height%3D%227px%22%20fill%3D%22%2373c9c4%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M5.276%2C6.599%20C5.064%2C6.599%204.852%2C6.519%204.691%2C6.358%20L0.308%2C2.005%20C-0.017%2C1.684%20-0.017%2C1.163%200.308%2C0.842%20C0.630%2C0.521%201.155%2C0.521%201.479%2C0.842%20L5.276%2C4.613%20L9.074%2C0.842%20C9.398%2C0.521%209.921%2C0.521%2010.245%2C0.842%20C10.568%2C1.163%2010.568%2C1.684%2010.245%2C2.005%20L5.862%2C6.358%20C5.700%2C6.519%205.489%2C6.599%205.276%2C6.599%20Z%22/%3E%3C/svg%3E');
        @media (max-width: 1366px) {
          right: 20px;
        }
      }
      @media (max-width: 1366px) {
        transform: none;
        position: static;
      }
    }

    @media (max-width: 1366px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 20px);
    }
  }

  &__sub-title {
    line-height: 1;
    font-size: 13px;
    font-weight: $font--light;
    color: $color-dark-grey;
    margin: 0 0 5px;

    &--turquoise {
      color: $color-light-turquoise;
    }
  }

  &__remainder-description {
    font-size: 16px;
    font-weight: $font--medium;
    color: $color-light-turquoise;
    margin: 0 0 15px;
    @media (max-width: 1366px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__tariff-description {
    font-size: 13px;
    font-weight: $font--medium;
    color: $color-dark-grey;
    margin: 0 0 22px;
  }

  &__extend-btn {
    display: block;
    font-size: 13px;
    text-transform: lowercase;
    color: #fff;
    background-color: $color-light-turquoise;
    border-radius: 6px;
    padding: 5px 19px 3px;
    margin: 0 auto;
    min-width: 128px;
    &.activated {
      background-color: $color-red;
    }
    @media (max-width: 1366px) {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
    }
    @media (max-width: 747px) {
      min-width: auto;
      width: 100%;
      padding: 5px 14px 5px;
    }
  }

  @media (max-width: 1366px) {
    .card-disable {
      .dashboard-services-card__title {
        color: #555;
      }
      .dashboard-services-card__icon {
        fill: #555;
      }
    }
  }
}
