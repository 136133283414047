.parking-form-neogeo {
  &__interactive-field {
    display: block;

    &:nth-child(even) {
      text-align: right;
    }

    &:nth-child(odd) {
      text-align: left;
    }
  }

  &__form-textarea {
    max-height: 100px;
    min-width: 450px;
    padding-bottom: 55px;
  }

  &__tooltipe {
    z-index: 5;
    background-color: $color-red !important;
    border-bottom-color: $color-red !important;
    &:after {
      border-bottom-color: $color-red !important;
    }
  }
}
