.prefill-form {
  &__col {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-right: 50px;
    &:last-of-type {
      padding-right: 0;
      padding-left: 50px;
    }

    @media (max-width: 747px) {
      padding-right: 0;
      width: 100%;
      &:last-of-type {
        padding-left: 0;
      }
    }
  }
  &__col-hidden {
    opacity: 0;
  }

  &__section {
    margin-bottom: 30px;
    &_top {
      padding-top: 20px;
    }
    &_last {
      margin-bottom: 0;
    }

    @media (max-width: 747px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
  }

  &__row-navigate {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    button {
      max-width: 45%;
      margin-top: 10px;
    }

    @media (max-width: 1366px) {
      flex-direction: column;
    }

    @media (max-width: 747px) {
      button {
        max-width: none;
      }
    }
  }

  &__row.InnForm {
    @media (max-width: 747px) {
      justify-content: flex-start;
      & > button {
        margin: 0;
      }
    }
  }

  &__date {
    color: $color-red;
    font-size: 18px;
    line-height: 1.1111111;
    width: 100%;
    position: relative;

    & input {
      padding-right: 0;
    }

    & input::placeholder {
      color: #555;
    }

    &--without-value input + span {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;

      &::after {
        background-color: $color-red;
        border-radius: 50%;
        content: '';
        height: 3px;
        left: -19px;
        position: absolute;
        top: 50%;
        width: 3px;
      }
    }

    @media (max-width: 1366px) {
      & > span::after {
        content: none !important;
      }
    }
  }

  &__input {
    position: relative;
    width: 100%;

    input[value=''] + span {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;

      &::after {
        background-color: $color-red;
        border-radius: 50%;
        content: '';
        height: 3px;
        left: -19px;
        position: absolute;
        top: 50%;
        width: 3px;
      }
    }

    &--file {
      input {
        display: none;
      }
    }

    &--error .input {
      border-color: $color-red;
    }

    @media (max-width: 1366px) {
      & > span::after {
        content: none !important;
      }
    }
  }

  &__input-error {
    position: absolute;
    color: $color-red;
    font-size: 10px;
  }

  &__file-field {
    display: block;
    height: 100%;
    overflow: hidden;
    padding: 13px 108px 11px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .prefill-form__file-upload-input.is-empty + & {
      font-weight: $font--light;
      overflow: visible;

      &::after {
        background-color: $color-red;
        border-radius: 50%;
        content: '';
        height: 3px;
        left: -19px;
        position: absolute;
        top: 50%;
        width: 3px;
      }
    }
  }

  &__btn-upload {
    border-radius: 6px;
    font-size: 13px;
    height: 26px;
    line-height: 26px;
    padding: 0 17px;
    position: absolute;
    right: 10px;
    text-transform: lowercase;
    top: 50%;
    transform: translateY(-50%);
  }

  &__hint {
    color: #555;
    display: block;
    font-size: 10px;
    line-height: 1.2;
    //padding-left: 20px;
    margin-top: 8px;
  }

  &__footer {
    padding: 25px 0 25px;
    text-align: center;
    @media (max-width: 1366px) {
      //padding: 0;
    }
  }

  &__btn {
    min-width: 245px;
    @media (max-width: 1366px) {
      //margin-top: -15px;
    }
    @media (max-width: 747px) {
      width: 100%;
      //margin-top: -20px;
    }
  }

  &__select {
    height: 42px;
    width: 100%;
    font-size: 18px;
    color: $color-dark-grey;
  }

  &__toggle-calendar.btn {
    background-color: #fff;
    border-radius: 3px;
    font-size: 0;
    height: 32px;
    position: absolute;
    width: 32px;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    z-index: 10;

    &::after {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23f05e4b%22%3E%3Cpath%20d%3D%22M3412.44%2C417.385h-1.21v-0.693a0.69%2C0.69%2C0%2C1%2C0-1.38%2C0v0.693h-9.7v-0.693a0.69%2C0.69%2C0%2C1%2C0-1.38%2C0v0.693h-1.21a1.561%2C1.561%2C0%2C0%2C0-1.56%2C1.557v13.5a1.561%2C1.561%2C0%2C0%2C0%2C1.56%2C1.558h14.88a1.561%2C1.561%2C0%2C0%2C0%2C1.56-1.558v-13.5A1.561%2C1.561%2C0%2C0%2C0%2C3412.44%2C417.385Zm0%2C15.23h-14.88a0.176%2C0.176%2C0%2C0%2C1-.18-0.173V422.231h15.24v10.211A0.176%2C0.176%2C0%2C0%2C1%2C3412.44%2C432.615ZM3399.66%2C424a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3399.66%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3403.22%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3406.78%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3410.34%2C424Zm-10.68%2C4.561a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3399.66%2C428.56Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3403.22%2C428.56Zm3.56-.89a1.781%2C1.781%2C0%2C1%2C0%2C1.78%2C1.78A1.783%2C1.783%2C0%2C0%2C0%2C3406.78%2C427.67Z%22%20transform%3D%22translate%28-3396%20-416%29%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      content: '';
      height: 18px;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
      transition: $transition--default;
      width: 18px;
    }

    .flatpickr-input.flatpickr-mobile + & {
      display: none;
    }
  }

  &__btn-mr-20 {
    margin-right: 20px;
  }

  &__btn-mla {
    margin-left: auto;
  }

  &__btn-top-back {
    @media (max-width: 1366px) {
      margin-left: 0;
    }
  }

  &__btn-container {
    display: flex;
    margin-right: -50px;
  }

  &__pc-visible {
    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__mobile-visible {
    display: none;
    @media (max-width: 1366px) {
      display: initial;
      & > div {
        margin-right: 0;
      }
    }
    @media (max-width: 747px) {
      & > div {
        justify-content: space-between;
        & button {
          margin: 0;
        }
      }
    }
  }

  &__col-inn-input {
    @media (max-width: 1366px) {
      width: 60%;
      padding-right: 0;
    }
    @media (max-width: 747px) {
      width: 100%;
    }
  }

  &__footer-last-step {
    @media (max-width: 1366px) {
      padding: 0;
    }
  }

  &__section-last {
    @media (max-width: 1366px) {
      margin-bottom: 15px;
    }
  }

  &__footer-individual {
    @media (max-width: 1366px) {
      padding: 0;
    }
  }

  &__error-container {
    @media (max-width: 1366px) {
      padding-bottom: 25px;
    }
  }

  &__section-individual-last {
    @media (max-width: 1366px) {
      margin-bottom: 15px;
    }
  }
}
