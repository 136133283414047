.profile-header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 747px) {
    padding: 0 20px;
  }

  &__icon {
    fill: $color-red;
    transition: $transition--default;
  }

  &__img-wrap {
    margin-left: 5px;
  }

  &__exit-btn {
    color: $color-brand;
    font-size: 16px;
    display: flex;
    margin-bottom: 25px;
    opacity: 0.9;
    transition: $transition--default;

    &:hover {
      opacity: 0.9;

      .profile-header__icon {
        fill: #ff6054;
      }
    }
    @media (max-width: 1366px) {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      //line-height: 32px;
    }

    @media (max-width: 747px) {
      //font-size: 20px;
      //line-height: 28px;
      font-size: 0;
      margin-bottom: 8px;
    }
  }

  &__mobile-pass {
    display: none;
    @media (max-width: 1366px) {
      display: initial;
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 24px;
      max-width: 230px;
    }
    @media (max-width: 747px) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
      padding: 0 20px;
      max-width: 230px;
    }
  }
}
