.simple-select {
  position: relative;
  display: inline-flex;

  select {
    appearance: none;
    display: inline-flex;
    width: 100%;
    color: #f15a4f;
    font-size: 14px;
    line-height: 1.1;
    font-family: Rubik, Arial, sans-serif;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    z-index: 1;
    background: transparent;
    padding-right: 20px;
  }

  &__toggle-button {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2011%207%22%20width%3D%2211px%22%20height%3D%227px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M5.276%2C6.599%20C5.064%2C6.599%204.852%2C6.519%204.691%2C6.358%20L0.308%2C2.005%20C-0.017%2C1.684%20-0.017%2C1.163%200.308%2C0.842%20C0.630%2C0.521%201.155%2C0.521%201.479%2C0.842%20L5.276%2C4.613%20L9.074%2C0.842%20C9.398%2C0.521%209.921%2C0.521%2010.245%2C0.842%20C10.568%2C1.163%2010.568%2C1.684%2010.245%2C2.005%20L5.862%2C6.358%20C5.700%2C6.519%205.489%2C6.599%205.276%2C6.599%20Z%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 7px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    transition: $transition--default;
    width: 11px;
  }
}
