.file-upload {
  width: 100%;

  &__inner {
    position: relative;
    @media (max-width: 1366px) {
      //max-width: 446px;
    }
  }

  &__input {
    display: none;
  }

  &__field {
    display: block;
    height: 100%;
    overflow: hidden;
    padding: 13px 108px 11px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .file-upload__input.is-empty + & {
      font-weight: $font--light;
      //overflow: visible;
    }
    @media (max-width: 1366px) {
      max-width: 100%;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 26px;
    padding: 0 17px;
    border-radius: 6px;
    font-size: 13px;
    line-height: 26px;
    text-transform: lowercase;
    transform: translateY(-50%);
  }

  &__hint {
    padding-left: 20px;
    margin-top: 10px;
    font-size: 10px;
    line-height: 1.2;
    color: #555;
  }
}
