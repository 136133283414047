.ticket-title {
  display: flex;
  color: $color-brand;
  align-items: baseline;

  &__id {
    font-weight: $font--medium;
    font-size: 18px;
  }

  &__time {
    padding-left: 6px;
    font-size: 14px;
    font-weight: $font--light;
  }
}
