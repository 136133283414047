.input-picker {
  position: relative;

  &__picker {
    overflow-y: scroll;
    width: 100%;
    position: absolute;
    z-index: 1;
    font-weight: 500;
    font-size: 18px;
    margin-top: 5px;
    border-radius: 4px;
    max-height: 150px;
    min-height: 15px;
    background-color: hsl(0, 0%, 98%);
    padding: 4px 8px 0 18px !important;
    color: $color-dark-grey !important;
    border: 1px solid $color-dark-grey;
  }
}
