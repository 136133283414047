.partner {
  border: 2px solid #d5dada;
  border-radius: 12px;
  margin: 35px auto 0;
  padding: 40px;
  width: 545px;

  &__description {
    color: #555;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2777777;
    margin: 10px 0 10px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__select {
    flex-grow: 1;
  }

  & .react-select__control {
    background-color: #fff;
  }
}
