.dashboard-services {
  margin-bottom: 21px;

  &__cards {
    margin: 0 -31px 0 0;
    width: 980px;
    @media (max-width: 1366px) {
      margin: 0;
      width: 100%;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    vertical-align: top;
    margin: 0 0 0 -31px;
    @media (max-width: 1366px) {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, 220px);
      gap: 20px;
      justify-content: center;
    }

    @media (max-width: 747px) {
      grid-template-columns: minmax(136px, 163px) repeat(auto-fit, minmax(136px, 163px));
      gap: 8px;
    }
  }

  &__item {
    width: 306px;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 0 20px 31px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
    overflow: hidden;
    @media (max-width: 1366px) {
      width: auto;
      margin: 0;
      min-height: 76px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      .dashboard-services-card {
        width: 100%;
      }
    }
  }
}
