.entrance-form {
  @media (max-width: 1366px) {
    min-width: 280px;
  }

  & .btn--with-arrow:not(&__btn_single) {
    text-align: left;
    padding-left: 24px;
    &::after {
      right: 15px;
    }
  }

  &__btn {
    @media (max-width: 1366px) {
      height: 40px;
      display: flex;
      align-items: center;
    }
    &--center {
      margin: 0 auto;
    }

    & + .entrance-form__btn {
      margin-left: 25px;
      @media (max-width: 1366px) {
        margin-left: 24px;
      }
    }

    &--grow {
      flex-grow: 1;
    }

    &--inline {
      padding: 4px 38px 3px 27px;
    }

    &_single {
      width: 100%;
      height: 40px;
      @media (max-width: 1366px) {
        max-width: 181px;
        text-align: left;
        justify-content: left;
      }
      @media (max-width: 747px) {
        text-align: center;
        justify-content: center;
        max-width: 280px;
        &::after {
          right: 66px;
        }
      }
    }

    &_registration {
      @media (max-width: 1366px) {
        width: 235px;
      }
      @media (max-width: 747px) {
        width: 100%;
        justify-content: center;
        align-self: center;
        //padding-right: 30px;
        &::after {
          position: static;
          transform: none;
          margin-left: 14px;
          //margin-right: 28px;
        }
      }
    }

    &_confirm {
      width: 174px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::after {
        position: static;
        transform: none;
      }
    }

    @media (max-width: 1366px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 18px;
      min-width: 117px;
    }
    @media (max-width: 747px) {
      font-size: 14px;
      line-height: 20px;
      min-width: 111px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    width: 100%;
    @media (max-width: 1366px) {
      margin-bottom: 24px;
    }
    @media (max-width: 747px) {
      margin-bottom: 16px;
    }

    &--with-elements {
      flex-wrap: nowrap;
      & > :not(:first-child) {
        margin-left: 25px;
      }
    }

    &--last {
      padding-bottom: 0;
      padding-top: 10px;
    }

    &--between {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__hint {
    font-size: 12px;
  }

  &__footer {
    padding: 15px 0;
    @media (max-width: 1366px) {
      padding: 0;
      margin-top: 32px;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }
    @media (max-width: 747px) {
      justify-content: space-between;
      margin-top: 24px;
    }
  }

  &__information-msg {
    background-color: rgba($color-light-turquoise, 0.3);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%209%2021%22%20width%3D%229px%22%20height%3D%2221px%22%20fill%3D%22%23f15a4f%22%3E%3Cpath%20d%3D%22M7.364%2C4.539%20C7.854%2C4.080%208.099%2C3.523%208.099%2C2.869%20C8.099%2C2.217%207.854%2C1.660%207.364%2C1.196%20C6.875%2C0.733%206.286%2C0.500%205.596%2C0.500%20C4.910%2C0.500%204.315%2C0.733%203.823%2C1.196%20C3.333%2C1.660%203.085%2C2.217%203.085%2C2.869%20C3.085%2C3.523%203.333%2C4.080%203.823%2C4.539%20C4.315%2C4.998%204.910%2C5.229%205.596%2C5.229%20C6.286%2C5.229%206.875%2C4.998%207.364%2C4.539%20ZM6.236%2C19.887%20C7.119%2C19.471%207.587%2C18.688%207.127%2C18.330%20C6.864%2C18.124%206.517%2C18.465%206.263%2C18.465%20C5.720%2C18.465%205.337%2C18.375%205.118%2C18.195%20C4.895%2C18.013%204.788%2C17.676%204.788%2C17.178%20C4.788%2C16.980%204.820%2C16.688%204.887%2C16.303%20C4.954%2C15.917%205.033%2C15.571%205.118%2C15.270%20L6.168%2C11.523%20C6.266%2C11.179%206.339%2C10.801%206.379%2C10.390%20C6.415%2C9.975%206.435%2C9.688%206.435%2C9.526%20C6.435%2C8.737%206.160%2C8.095%205.611%2C7.600%20C5.064%2C7.107%204.282%2C6.858%203.268%2C6.858%20C2.705%2C6.858%202.105%2C6.942%201.478%2C7.161%20C0.246%2C7.596%200.520%2C8.676%200.793%2C8.676%20C1.348%2C8.676%201.720%2C8.771%201.916%2C8.960%20C2.113%2C9.148%202.214%2C9.482%202.214%2C9.964%20C2.214%2C10.228%202.179%2C10.526%202.116%2C10.847%20C2.052%2C11.168%201.976%2C11.510%201.881%2C11.870%20L0.825%2C15.631%20C0.735%2C16.026%200.667%2C16.380%200.622%2C16.694%20C0.579%2C17.008%200.560%2C17.315%200.560%2C17.616%20C0.560%2C18.388%200.842%2C19.025%201.407%2C19.528%20C1.973%2C20.032%202.842%2C20.500%203.862%2C20.500%20C4.524%2C20.500%205.470%2C20.252%206.236%2C19.887%20Z%22/%3E%3C/svg%3E');
    background-position: 20px 10px;
    background-repeat: no-repeat;
    border-radius: 3px;
    color: $color-dark-grey;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 11px 19px 11px 50px;
    @media (max-width: 1366px) {
      max-width: 280px;
      margin-top: 32px;
    }
    &_mb0 {
      @media (max-width: 747px) {
        margin-bottom: 0;
      }
    }
  }

  &__captcha {
    margin: 0 auto;
  }

  &__checkbox {
    margin-bottom: 5px;
    @media (max-width: 1366px) {
      label {
        font-size: 12px !important;
        padding-left: 35px !important;
        &::before {
          width: 24px !important;
          height: 24px !important;
        }
        &::after {
          width: 16px !important;
          height: 10px !important;
          top: 1px !important;
          left: 4px !important;
        }
      }
    }
    @media (max-width: 747px) {
      label {
        padding-left: 30px !important;
      }
    }
  }

  &__date {
    color: $color-red;
    font-size: 18px;
    line-height: 1.1111111;
    width: 100%;
    position: relative;

    & input {
      padding-right: 0;
    }
  }

  &__toggle-calendar.btn {
    background-color: #fff;
    border-radius: 3px;
    font-size: 0;
    height: 32px;
    position: absolute;
    width: 32px;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    z-index: 10;

    &::after {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23f05e4b%22%3E%3Cpath%20d%3D%22M3412.44%2C417.385h-1.21v-0.693a0.69%2C0.69%2C0%2C1%2C0-1.38%2C0v0.693h-9.7v-0.693a0.69%2C0.69%2C0%2C1%2C0-1.38%2C0v0.693h-1.21a1.561%2C1.561%2C0%2C0%2C0-1.56%2C1.557v13.5a1.561%2C1.561%2C0%2C0%2C0%2C1.56%2C1.558h14.88a1.561%2C1.561%2C0%2C0%2C0%2C1.56-1.558v-13.5A1.561%2C1.561%2C0%2C0%2C0%2C3412.44%2C417.385Zm0%2C15.23h-14.88a0.176%2C0.176%2C0%2C0%2C1-.18-0.173V422.231h15.24v10.211A0.176%2C0.176%2C0%2C0%2C1%2C3412.44%2C432.615ZM3399.66%2C424a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3399.66%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3403.22%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3406.78%2C424Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3410.34%2C424Zm-10.68%2C4.561a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3399.66%2C428.56Zm3.56%2C0a0.891%2C0.891%2C0%2C1%2C1-.89.89A0.891%2C0.891%2C0%2C0%2C1%2C3403.22%2C428.56Zm3.56-.89a1.781%2C1.781%2C0%2C1%2C0%2C1.78%2C1.78A1.783%2C1.783%2C0%2C0%2C0%2C3406.78%2C427.67Z%22%20transform%3D%22translate%28-3396%20-416%29%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      content: '';
      height: 18px;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
      transition: $transition--default;
      width: 18px;
    }
  }

  &__section-btn {
    border: none;
    background-color: transparent;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: $font--semi-bold;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 15px;
    transition: $transition--default;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:not(:disabled):hover {
      color: $color-red;
    }
    @media (max-width: 1366px) {
      padding: 0;
    }
  }

  .mobile-error-login {
    display: none;
    @media (max-width: 1366px) {
      display: block;
      margin: 0;
    }
  }

  .desktop-error-login {
    @media (max-width: 1366px) {
      display: none;
    }
  }

  .no-margin {
    @media (max-width: 1366px) {
      margin: 0;
    }
  }
  .mr-32 {
    @media (max-width: 747px) {
      margin-bottom: 30px;
    }
  }
}

.has-error {
  .mobile-error-login {
    @media (max-width: 1366px) {
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }
}
