.support {
  @media (max-width: 747px) {
    margin-bottom: 0;
    height: 100%;
  }

  &__title-main {
    padding-left: 20px;
    margin-bottom: 24px;
  }

  &__location-picker {
    margin-bottom: 40px;
    & .location-picker {
      max-height: 41px;
    }
  }

  &__information {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media (max-width: 1366px) {
      margin-bottom: 62px;
    }
    @media (max-width: 747px) {
      flex-direction: column;
      margin-bottom: 22px;
    }
  }

  &__contacts {
    flex: 1 1 48%;
    @media (max-width: 1366px) {
      max-width: 325px;
    }
    @media (max-width: 747px) {
      flex: 1;
      max-width: 100%;
      margin-bottom: 34px;
    }
  }
  &__contacts-list {
    padding: 0;
    margin: 0 0 0 30px;
    list-style: none;
    @media (max-width: 1366px) {
      margin-left: 0;
    }
  }

  &__title {
    margin-bottom: 18px;
    //@media (max-width: 1366px) {
    //  margin-bottom: 8px;
    //}
  }

  &__contacts-item {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    @media (max-width: 1366px) {
      margin-bottom: 8px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__contacts-link {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__contacts-img-wrap {
    position: relative;
    width: 20px;
    margin-right: 8px;
  }
  &__contacts-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__links {
    flex: 1 1 30%;
    @media (max-width: 1366px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 15%;
    }
    @media (max-width: 747px) {
      flex: 1;
      max-width: 100%;
      padding-right: 0;
      align-items: flex-start;
    }
  }

  &__links-list {
    padding: 0 0 0 17px;
    margin: 0;
    color: $color-red;
    @media (max-width: 1366px) {
      padding-left: 28px;
    }
  }

  &__links-item {
    font-size: 13px;
    line-height: 1.6;
    @media (max-width: 1366px) {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__managers {
    margin-bottom: 26px;
    @media (max-width: 1366px) {
      margin-bottom: 40px;
    }
    @media (max-width: 747px) {
      margin-bottom: 20px;
    }
  }

  &__managers-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    @media (max-width: 747px) {
      flex-direction: column;
    }
  }

  &__managers-item {
    flex: 0 1 33%;
    padding-left: 30px;
    margin-bottom: 20px;
    max-width: 33%;
    @media (max-width: 1366px) {
      max-width: none;
      padding-left: 0;
      &:first-child {
        flex: 1 1 48%;
        max-width: 325px;
      }
      &:last-child {
        flex: 1 1 30%;
        display: flex;
        justify-content: center;
        padding-right: 10%;
      }
    }

    @media (max-width: 747px) {
      &:first-child,
      &:last-child {
        max-width: 100%;
        flex: 1;
        padding: 0;
        justify-content: flex-start;
      }
    }
  }

  &__requests-button {
    min-width: 152px;
    margin-bottom: 18px;
    @media (max-width: 1366px) {
      margin-bottom: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 367px;
    }
  }

  &__contacts-text {
    @media (max-width: 747px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__btn-cont {
    @media (max-width: 747px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__personal-id {
    min-width: 152px;
    @media (max-width: 1366px) {
      margin-top: 10px;
    }
    @media (max-width: 747px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
