.std-table {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;

  &__title {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.1111111;
    margin: 0 0 20px;
    padding-left: 35px;
    @media (max-width: 1366px) {
      padding-left: 0;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__overflow-table {
    @media (max-width: 1366px) {
      overflow: auto;
      //margin: 0 -40px;
      padding-bottom: 24px;
    }
    @media (max-width: 747px) {
      //margin: 0 -20px;
    }
  }

  &__blur-container {
    @media (max-width: 1366px) {
      position: relative;
      margin: 0 -40px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
      }
    }
    @media (max-width: 747px) {
      margin: 0 -20px;
    }
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    @media (max-width: 1366px) {
      min-width: 880px;
    }
  }

  &__table-head {
    color: #818181;
    font-weight: 400;
    font-size: 13px;
    height: 40px;
  }

  &__row {
    height: 30px;
    background-color: #f6f6f6;
    @media (max-width: 1366px) {
      .std-table__cell:last-of-type {
        background-color: #f6f6f6;
      }
    }

    &:nth-of-type(2n) {
      background-color: #fff;
      @media (max-width: 1366px) {
        .std-table__cell:last-of-type {
          background-color: #fff;
        }
      }
    }
    @media (max-width: 1366px) {
      height: 48px;
    }
    @media (max-width: 747px) {
      height: 40px;
    }
  }

  &__cell {
    padding-left: 35px;
    &:last-of-type {
      padding-right: 10px;
      @media (max-width: 1366px) {
        padding-right: 0;
        position: relative;
        z-index: 2;
      }
    }
    @media (max-width: 1366px) {
      padding-left: 40px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #555555;
    }
    @media (max-width: 747px) {
      padding-left: 20px;
    }
    &--head {
      text-transform: uppercase;
      @media (max-width: 1366px) {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &--paid {
      color: #73c9c4;
      font-weight: $font--normal;
      @media (max-width: 1366px) {
        span {
          text-decoration: underline dotted;
        }
      }
    }

    &--not-paid {
      color: $color-light-green;
      font-weight: $font--normal;
    }

    &--amount-payment {
      font-weight: $font--normal;
    }

    &--payment {
      font-weight: $font--normal;
    }
  }

  &__limited-cell-content {
    max-width: 270px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: inherit;
  }

  &__link-payment {
    color: $color-red;
    text-decoration: none;
    vertical-align: inherit;
    cursor: pointer;
    &:hover {
      color: #ff6054;
    }
  }

  @media (max-width: 1366px) {
    padding: 40px;
    .not-history {
      display: flex;
      justify-content: center;
      p {
        max-width: 280px;
        margin: 0;
      }
    }
  }

  @media (max-width: 747px) {
    flex: 1;
    padding: 40px 20px;
    .not-history {
      margin-top: 20px;
    }
  }
}
