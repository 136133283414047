@mixin down($point) {
  @media (max-width: $point - 1) {
    @content;
  }
}

.workplace-floor-wrapper {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  text-decoration: none;
  display: flex;
}

.workplace-floor-box {
  :hover {
    cursor: grab;
  }

  background: repeating-linear-gradient(-60deg, #cfd1d2 0, #cfd1d2 1px, transparent 1px, transparent 5px);
  display: flex;
  flex-direction: column;
  height: 80vh;
  //margin-top: 40px;
  margin: 30px;
  overflow-x: scroll;
  overflow-y: scroll;
  @include down(970px) {
    flex-direction: column-reverse;
    margin: 0 -60px;
    .innerWrapper {
      padding-left: 60px;
    }
    .floorAreaInner {
      left: 60px;
    }
  }
  @include down(744px) {
    flex-direction: column-reverse;
    margin: 0 -20px;
    .innerWrapper {
      padding-left: 20px;
    }
    .floorAreaInner {
      left: 20px;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }
}
.workplace-control-buttons-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workplace-scaling-buttons-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.workplace-scaling-button {
  padding: 12px;
  width: 48px;
  height: 48px;
  border: 1px solid #cfd1d2;
  margin: 10px;
  border-radius: 3px;
  background-color: #cfd1d2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.workplace-scaling-button:hover {
  background-color: #cfd1d2;
  cursor: pointer;
  &:hover {
    & path,
    & rect {
      stroke: white;
      fill: white;
    }
  }
}

.workplace-floor-wrapper-inner {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  transition: scale 0.4s ease-in-out;
  will-change: scale;
}

.workplace-floor-area-template {
  pointer-events: all;
}

.workplace-floor-area-path {
  transition: all 0.2s ease-out;
  fill: transparent;
}

.workplace-floor-area-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  max-width: 1350px;
  height: auto;
  @media (min-width: 1280px) {
    width: 100%;
  }
  @media (min-width: 1792px) {
    max-width: 1550px;
  }
}

.workplace-template-path {
  transition: all 0.2s ease-out;
  fill: transparent;
}

.workplace-box {
  overflow: visible;
}

.workplace-template-workplace {
  transform-origin: 50% 50%;
  transition: all 0.2s ease-out;
  fill: $wp-grayscale-middle;
  cursor: pointer;

  & path,
  & circle,
  & rect {
    transition: inherit;
    stroke: $wp-grayscale-dark;
    fill: $wp-grayscale-middle;
  }

  &:hover {
    & path,
    & rect {
      fill: $wp-red-main;
      stroke: $wp-red-plan;
    }
  }
}

.workplace-floor-place-item {
  outline: none;

  .workplace-template-workplace {
    outline: none;

    &:focus {
      & path,
      & rect {
        fill: $wp-red-main;
        stroke: $wp-red-plan;
      }
    }
  }
}
.filters-active {
  &:focus-within {
    & > path {
      fill: $wp-green-bg !important;
      outline: none;
    }
  }

  fill: $wp-green-bg !important;
}

.workplace-floor-cabinet-item {
  &:focus-within {
    & > path {
      fill: $wp-green-bg;
      outline: none;
    }

    & > svg > .workplace-template-workplace {
      & path,
      & rect {
        fill: $wp-red-main;
        stroke: $wp-red-plan;
      }
    }
  }

  .workplace-template-workplace {
    pointer-events: none;
  }

  .workplace-template-path {
    transition: all 0.2s ease-out;
    fill: transparent;
    cursor: pointer;

    &:hover {
      fill: $wp-green-bg;
      cursor: pointer;

      & ~ svg > .workplace-template-workplace {
        & path,
        & circle,
        & rect {
          fill: $wp-red-main;
          stroke: $wp-red-plan;
        }
      }
    }
  }
}

.workplace-cabinet-item-disabled {
  pointer-events: none;

  & > svg > .workplace-template-workplace {
    & path,
    & rect {
      fill: $wp-grayscale-main;
      stroke: $wp-grayscale-secondary;
    }
  }
}

.workplace-template-disabled {
  pointer-events: none;

  & path,
  & rect {
    fill: $wp-grayscale-main;
    stroke: $wp-grayscale-secondary;
    pointer-events: none;
  }
}

.workplace-template-active {
  & path,
  & rect {
    fill: $wp-red-main;
    stroke: $wp-red-plan;
  }
}

.workplace-floor-description {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1366px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.workplace-floor-description-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;
  pointer-events: none;
  max-width: 280px;
}

.workplace-floor-description-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.workplace-floor-shadow {
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.2));
  @media (max-width: 1279px) {
    filter: none;
  }
}

.workplace-floor-area-inner {
  max-width: 1350px;
  height: auto;
  overflow: visible;
  @media (max-width: 747px) {
    margin-right: 20px;
  }
  @media (min-width: 1280px) {
    margin-right: 0;
  }
  @media (min-width: 1610px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 100%;
  }
  @media (min-width: 1792px) {
    max-width: 1550px;
  }
}

.workplace-text {
  fill: white;
  stroke: white;
  stroke-width: 0;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.55px;
}

.workplace-text-rotate {
  transform: rotate(180deg) translate(0, -20px);
  transform-origin: 50% 50%;
}
