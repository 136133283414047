.react-select {
  &__control {
    background-color: hsl(0, 0%, 98%);
    box-shadow: unset !important;

    &--is-focused {
      border-color: $color-dark-grey !important;
      box-shadow: unset !important;
      &:hover {
        border-color: $color-dark-grey !important;
      }
    }

    .includes-select--has-error & {
      border-color: $color-red !important;
    }
  }

  &__value-container {
    font-size: 18px !important;
    padding: 4px 8px 0 18px !important;
    color: $color-dark-grey !important;
  }

  &__placeholder {
    top: 54% !important;
  }

  &__single-value {
    color: $color-dark-grey !important;
    top: 54% !important;
    font-weight: 500 !important;
  }

  &__option {
    &--is-selected {
      background-color: $color-light-turquoise !important;
    }
  }
}
