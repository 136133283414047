.text {
  &-red {
    color: $color-red;
  }

  &-white {
    color: $color-white;
  }

  &-light-weight {
    font-weight: $font--light;
  }
}
