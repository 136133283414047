.toggle {
  display: inline-block;
  height: 18px;
  position: relative;

  &__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__outside {
    background: transparent;
    border: 1px solid $color-dark-grey;
    border-radius: 9px;
    height: 18px;
    overflow: hidden;
    position: relative;
    transition: $transition--default;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin: 2px 10px;
  }

  &__inside {
    border-radius: 50%;
    height: 12px;
    position: absolute;
    top: 2px;
    transition: $transition--default;
    width: 12px;
  }

  &__label {
    font-size: 14px;
    font-weight: $font--medium;
    opacity: 0.5;
    text-transform: lowercase;
    line-height: 1.4285714;

    &--on {
      color: $color-red;
    }

    &--off {
      color: $color-dark-grey;
    }
  }

  &__input ~ {
    .toggle__outside .toggle__inside {
      background-color: $color-dark-grey;
      left: 22px;
    }

    .toggle__outside {
      border-color: $color-dark-grey;
    }

    .toggle__label--on {
      opacity: 0.5;
    }

    .toggle__label--off {
      color: #505050;
      opacity: 1;
    }
  }

  &__input:checked ~ {
    .toggle__outside .toggle__inside {
      background-color: $color-red;
      left: 2px;
    }

    .toggle__outside {
      border-color: $color-red;
    }

    .toggle__label--on {
      opacity: 1;
    }

    .toggle__label--off {
      opacity: 0.5;
    }
  }
}
