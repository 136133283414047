.select {
    height: 42px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%;

    &__control {
        align-items: center;
        background-color: #fff;
        border: 1px solid rgba($color-dark-grey, 0.2);
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0;
        position: relative;
        transition: all 100ms;
        height: 100%;

        &:hover {
            border-color: $color-brand;
        }

        .select.is-open & {
            border-radius: 3px 3px 0 0;
            border-bottom: 1px solid transparent;

            &:hover {
                border-color: rgba($color-dark-grey, 0.2);
                border-bottom-color: transparent;
            }
        }
    }

    &__value-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 0%;
        font-weight: $font--medium;
        padding: 11px 20px 8px;
        position: relative;
    }

    &__indicators {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        padding: 0 10px;
    }

    &__indicator-separator {
        display: none;
    }

    &__indicator {
        background-color: transparent;
        color: $color-secondary;
        height: 20px;
        position: relative;
        transition: color 150ms;
        width: 20px;

        & > svg {
            position: absolute;
            fill: $color-red;
            top: 0;
            right: 0;
        }
    }

    &__placeholder {
        color: #909090;
        margin-left: 2px;
        margin-right: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__menu {
        background-color: #fff;
        border: 1px solid rgba($color-dark-grey, 0.2);
        border-top-width: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1;
        border-radius: 0 0 3px 3px;
    }

    &__menu-list {
        max-height: 300px;
        overflow-y: auto;
        position: relative;
    }

    &__option {
        cursor: default;
        display: block;
        font-size: inherit;
        padding: 8px 25px 6px 20px;
        user-select: none;
        width: 100%;

        &--is-focused {
            background-color: #f3f5f7;
            color: $color-secondary;
        }

        &--is-selected {
            background-color: #f3f5f7;
        }
    }

    &__single-value {
        margin-left: 2px;
        margin-right: 2px;
        max-width: calc(100% - 20px);
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;

        .select__control-is-disabled & {
            color: #909090;
        }
    }

    &__group {
        padding-bottom: 5px;
        padding-top: 5px;
        border-bottom: 1px solid;
        border-bottom-color: rgba($color-dark-grey, 0.2);

        &:last-child {
            border-bottom: none;
        }
    }
}
