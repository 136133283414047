.flatpickr-calendar {
  width: 280px;
  border: 2px solid rgb(230, 230, 230);
  border-radius: 2px;
  box-shadow: 8px 14px 30px 0 rgba(232, 232, 232, 0.004);
  font-family: $font-stack-base;
  font-size: 13px;
  padding: 20px 0;
  margin-top: 10px;
  @media (max-width: 747px) {
    left: 50% !important;
    transform: translate(-50%) !important;
  }
}
.flatpickr-calendar.animate.open {
  transform: translateX(-25%);
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  content: none;
}

.flatpickr-months .flatpickr-month {
  margin-bottom: 15px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 20px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 20px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 20px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  fill: $color-red;
}

.flatpickr-current-month {
  font-size: 16px;
}

.flatpickr-current-month span.cur-month {
  font-weight: $font--medium;
  color: #555;
}

.flatpickr-weekdays {
  display: none !important;
}

.dayContainer {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  padding: 0 22px;
}

.flatpickr-day {
  font-weight: $font--light;
  max-width: 30px;
  height: 30px;
  line-height: 30px;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $color-red;
  border-color: $color-red;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: #bfbfbf;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  color: #bfbfbf;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0) translateX(-25%);
    transform: translate3d(0, -20px, 0) translateX(-25%);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) translateX(-25%);
    transform: translate3d(0, 0, 0) translateX(-25%);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0) translateX(-25%);
    transform: translate3d(0, -20px, 0) translateX(-25%);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) translateX(-25%);
    transform: translate3d(0, 0, 0) translateX(-25%);
  }
}
