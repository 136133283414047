.workplace-legend-item {
  display: flex;
  align-items: center;

  &__color {
    border: 1px solid transparent;
    border-radius: 3px;
    height: 13px;
    width: 25px;
    margin-right: 10px;

    .workplace-legend-item--free & {
      border-color: #499c55;
      background-color: #499c55;
    }

    .workplace-legend-item--occupied & {
      border-color: #697096;
      background-color: #697096;
    }

    .workplace-legend-item--chosen & {
      border-color: #f05e4b;
      background-color: #f05e4b;
    }

    .workplace-legend-item--rooms & {
      border-color: #e64236;
      background-color: transparent;
    }

    &--circle {
      width: 17px;
      height: 17px;
      border-radius: 50%;

      .workplace-legend-item--rooms & {
        border-color: #e64236;
        background-color: transparent;
      }
    }
  }

  &__wrap-icon {
    margin-right: 10px;
  }

  &__icon {
    fill: #555;
    display: inline-block;
    transition: $transition--default;
    vertical-align: middle;
  }

  &__name {
    font-size: 14px;
    color: #818181;
    font-style: italic;
    line-height: 1.1;
  }
}
