.workplace-floor-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  #city_plan {
    width: 950px;
    height: 918px;
  }

  #neo-geo_floor_1_block_a_plan {
    width: 950px;
    height: 765px;
  }

  #neo-geo_floor_1_block_c_plan {
    width: 950px;
    height: 450px;
  }

  #neo-geo_floor_2_plan {
    width: 950px;
    height: 700px;
  }

  #komsomolskaya_floor_1_plan {
    width: 950px;
    height: 492px;
  }

  #komsomolskaya_floor_2_plan {
    width: 950px;
    height: 510px;
  }

  #komsomolskaya_floor_3_plan {
    width: 950px;
    height: 514px;
  }

  #komsomolskaya_floor_4_plan {
    width: 950px;
    height: 445px;
  }

  &__svg-container {
    width: 100%;
    @media (max-width: 1366px) {
      overflow: auto;
      position: relative;
    }
  }

  &__btn-zoom-container {
    display: none;
    @media (max-width: 1366px) {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
    }
  }

  &__btn-zoom {
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    border: none;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__zoom_sm {
    transform-origin: 0 0;
    transform: scale(1);
  }

  &__zoom_md {
    transform-origin: 0 0;
    transform: scale(2.5);
  }

  &__zoom_lg {
    transform-origin: 0 0;
    transform: scale(4.5);
  }

  &__svg {
    @media (max-width: 1366px) {
      width: 100% !important;
      height: auto !important;
    }
  }
  &__svg-cell {
    fill: #499c55;
    transition: $transition--default;

    &.is-free {
      fill: #499c55;
    }

    &.is-busy,
    &.is-busy:hover {
      fill: #697096;
    }

    &:hover,
    &.is-active {
      fill: #f05e4b;
    }
  }

  &__legend {
    margin-top: 20px;
  }

  &__legend-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__legend-item {
    margin-bottom: 0;
    margin-right: 30px;
    width: auto;

    @media (max-width: 1366px) {
      margin-top: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
