.textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 166px;
  padding: 18px 21px;
  border: 1px solid #ddd;
  font-family: $font-stack-base;
  font-weight: $font--light;
  font-size: 18px;
  line-height: 25px;
  color: $color-dark-grey;
  border-radius: 3px;
  overflow: auto;
  box-shadow: none;

  &.has-error {
    border-color: $color-red;
  }

  &:focus {
    border-color: $color-dark-grey;
    outline: none;
  }

  &--with-autosize {
    height: unset;
    padding: 8px 18px 8px;
    color: $color-dark-grey;
    font-size: 18px;
    font-weight: $font--medium;

    @include placeholder() {
      font-weight: $font--light;
    }

    &:disabled {
      background-color: #f5f6f7;
      cursor: not-allowed;
      opacity: 0.8;
    }
  }
}
