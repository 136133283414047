.dashboard-news {
    border-radius: 4px;
    box-shadow: 1px 1px 1px 0 rgba(85, 85, 85, 0.2);
    background-color: #fff;
    padding: 25px 25px 40px 20px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: $color-dark-grey;

    &__item:not(:last-child) {
        margin-bottom: 25px;
    }

    &__date {
        font-weight: $font--medium;
        margin: 0 0 5px;
    }

    &__description {
        font-weight: $font--light;
        margin: 0;
    }

    &__description--less {
        max-height: 85px;
        overflow: hidden;
    }

    &__show-more-btn {
        margin-top: 15px;
    }
}
